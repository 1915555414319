import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: {
			'Welcome to React': 'Welcome to React and react-i18next',
			management: {
				game: {
					card: {
						play: 'Play',
					},
				},
			},
			userInfo: {
				title: 'Enter your data',
				content: 'Please enter your name to be shown in the app',
				fields: {
					name: 'Name',
				},
				buttons: {
					save: 'Save',
				},
			},
			gameMenu: {
				options: {
					share: 'Share screen',
				},
			},
			gameControl: {
				options: {
					countdown: 'Activate countdown',
					share: 'Share screen',
					stopShare: 'Stop sharing',
					musicShare: 'Share music',
					folderShared: 'Open File Manager',
					rollDice: 'Roll a dice d',
					rollDices: 'Roll multiple dices',
					clearDices: 'Clear dices',
					openControlBoard: 'Open control board in new page',
					uploadFile: 'Upload files',
					account: 'User info',
					logs: 'Show Logs',
					playingMusing: 'Playing music',
					chat: 'Show chat',
					rules: 'Game System rules',
				},
			},
			diceResults: {
				repeat: {
					title: 'There are {{diceNumber}} dices to reroll, do you want to roll them?',
				},
			},
			diceSelector: {
				title: 'Dice Selector',
				content: 'Choose your dices',
				isl5rDice: 'Use L5R dices',
				buttons: {
					roll: 'Roll',
					clean: 'Clean',
					cancel: 'Cancel',
				},
			},
			UserInfoSelector: {
				title: 'Edit your info',
				content: 'Please update any info that you desire',
				fields: {
					name: 'Name',
				},
				buttons: {
					save: 'Save',
					cancel: 'Cancel',
				},
				deleteAvatarDialog: {
					title: 'Delete avatar',
					text: 'Are you sure?',
					buttons: {
						success: 'Delete',
						cancel: 'Cancelar',
					},
				},
			},
			camerasBoard: {
				cameraType: {
					title: 'Choose camera layout',
					default: 'By default',
					highlighted_users: 'High light layout',
				},
			},
			fileManager: {
				title: 'Files manager',
			},
			LibrariesManager: {
				title: 'Library manager',
				tabs: {
					public: 'Public',
					private: 'Mine',
					rules: 'Rules',
				},
				content: {
					folders: 'Folders',
					files: 'Files',
					createFolder: 'Create folder',
				},
				createFolderDialog: {
					title: 'Create folder',
					content: 'Write a name for the folder',
					placeholder: 'Name of the folder',
					existsFolder: 'The given folder name already exists',
				},
				createLibraryDialog: {
					title: 'Create Library',
					content: 'Write a name for the library',
					placeholder: 'Name of the library',
					exists: 'The given library name already exists',
				},
				errors: {
					uploadFile: 'There has been an error uploading {{filename}} ',
					deleteFile: 'There has been an error deleting {{filename}} ',
				},
			},
			streamMenu: {
				remove: 'Remove',
				resync: 'Resync',
			},
			chat: {
				warningSecretChat: '{{from}} has send a secret message to {{to}}',
				minimizedChat: {
					title: 'Chat',
				},
				chatContent: {
					allTitle: 'All the users',
					placeholder: 'Write your message',
					send: 'Send',
				},
			},
			countDown: {
				hours: 'Hours',
				minutes: 'Minutes',
				seconds: 'Seconds',
			},
			dragnDrop: {
				text: 'Drag’n’drop some files here, or click to select files',
				button: 'Select Files',
			},
		},
	},
	es: {
		translation: {
			'Welcome to React': 'Bienvenido',
			management: {
				game: {
					card: {
						play: 'Jugar',
					},
				},
			},
			userInfo: {
				title: 'Introduce tus datos',
				content: 'Oye me harías el favor de introducir tus datos personales?',
				fields: {
					name: 'Nombre',
				},
				buttons: {
					save: 'Guardar',
				},
			},
			gameMenu: {
				options: {
					share: 'Compartir pantalla',
				},
			},
			gameControl: {
				options: {
					countdown: 'Activar cuenta atrás',
					share: 'Compartir pantalla',
					stopShare: 'Parar de compartir',
					musicShare: 'Compartir música',
					folderShared: 'Abrir gestor de ficheros',
					rollDices: 'Tirar dados',
					rollDice: 'Tira un dado de',
					clearDices: 'Limpiar dados',
					openControlBoard: 'Abrir control en nueva ventana',
					uploadFile: 'Subir ficheros',
					account: 'Información de usuario',
					logs: 'Mostrar Logs',
					playingMusing: 'Musica sonando',
					chat: 'Abrir chat',
					rules: 'Reglas de juego',
				},
			},
			diceResults: {
				repeat: {
					title: 'Hay {{diceNumber}} dados por relanzar, Quieres lanzarlos?',
				},
			},
			diceSelector: {
				title: 'Selector de dados',
				content: 'Selecciona dados',
				isl5rDice: 'Usar dados de Leyenda de los 5 Anillos',
				buttons: {
					roll: 'Lanzar',
					clean: 'Limpiar',
					cancel: 'Cancelar',
				},
			},
			UserInfoSelector: {
				title: 'Edita tus datos',
				content: 'Aquí puedes actualizar cualquier cosa que quieras',
				fields: {
					name: 'Nombre',
				},
				buttons: {
					save: 'Guardar',
					cancel: 'Cancelar',
				},
				deleteAvatarDialog: {
					title: 'Eliminar avatar',
					text: 'Seguro que quieres eliminarlo?',
					buttons: {
						success: 'Eliminar',
						cancel: 'Cancelar',
					},
				},
			},
			camerasBoard: {
				cameraType: {
					title: 'Elige plantilla ',
					default: 'Por defecto',
					highlighted_users: 'Destaca jugadores',
				},
			},
			fileManager: {
				title: 'Gestor de Ficheros',
			},
			LibrariesManager: {
				title: 'Gestor de Librerias',
				tabs: {
					public: 'Pública',
					private: 'Mía',
					rules: 'Reglas',
				},
				content: {
					folders: 'Carpetas',
					files: 'Ficheros',
					createFolder: 'Crear Carpeta',
				},
				createFolderDialog: {
					title: 'Crear Carpeta',
					content: 'Escribe un nombre para la carpeta',
					placeholder: 'Nombre de la carpeta',
					existsFolder: 'Esta carpeta ya existe',
				},
				createLibraryDialog: {
					title: 'Crea una librería',
					content: 'Escribe un nombre para la librería',
					placeholder: 'Nombre de la librería',
					exists: 'Esta librería ya existe',
				},
				errors: {
					uploadFile: 'Algo ha fallado al crear {{filename}} ',
					deleteFile: 'Algo ha fallado al eliminar {{filename}} ',
				},
			},
			streamMenu: {
				remove: 'Quitar jugador (Sólo para mi)',
				resync: 'Reconectar',
			},
			chat: {
				warningSecretChat: '{{from}} ha enviado un mensaje secreto a {{to}}',
				minimizedChat: {
					title: 'Chat',
				},
				chatContent: {
					allTitle: 'Todos los jugadores',
					placeholder: 'Escribe aquí',
					send: 'Enviar',
				},
			},
			countDown: {
				hours: 'Horas',
				minutes: 'Minutos',
				seconds: 'Segundos',
			},
			dragnDrop: {
				text: 'Arrastra algunos ficheros aquí, or haz click en el botón',
				button: 'Subir fichero',
			},
		},
	},
	ca: {
		translation: {
			'Welcome to React': 'Benvingut',
			management: {
				game: {
					card: {
						play: 'Jugar',
					},
				},
			},
			userInfo: {
				title: 'Introdueix les dades',
				content: 'Pots afegir les teves dades?',
				fields: {
					name: 'Nom',
				},
				buttons: {
					save: 'Guardar',
				},
			},
			gameMenu: {
				options: {
					share: 'Compartir pantalla',
				},
			},
			gameControl: {
				options: {
					countdown: 'Activar compte enrere',
					share: 'Compartir pantalla',
					stopShare: 'Parar de compartir',
					musicShare: 'Compartir música',
					folderShared: 'Obrir gestor de fitxers',
					rollDices: 'Llançar daus',
					rollDice: 'Llança un dau de',
					clearDices: 'Neteja daus',
					openControlBoard: 'Obrir control en nova finestra',
					uploadFile: 'Subir fitxers',
					account: "Informació d'usuari",
					logs: 'Mostrar Logs',
					playingMusing: 'Música actual',
					chat: 'Obrir chat',
					rules: 'Regles de joc',
				},
			},
			diceResults: {
				repeat: {
					title: 'Hi ha {{diceNumber}} daus por tornar a llançar, Vols fer-ho?',
				},
			},
			diceSelector: {
				title: 'Sel·lector de daus',
				content: 'Sel·lecciona dau',
				isl5rDice: 'Fer servir daus de Llegenda dels 5 Anyells',
				buttons: {
					roll: 'Llançar',
					clean: 'Netejar',
					cancel: 'Cancelar',
				},
			},
			UserInfoSelector: {
				title: 'Edita les teves dades',
				content: 'Aquí pots actualitzar les teves dades',
				fields: {
					name: 'Nom',
				},
				buttons: {
					save: 'Guardar',
					cancel: 'Cancelar',
				},
				deleteAvatarDialog: {
					title: 'Eliminar avatar',
					text: 'Segur que vols eliminar-lo?',
					buttons: {
						success: 'Eliminar',
						cancel: 'Cancelar',
					},
				},
			},
			camerasBoard: {
				cameraType: {
					title: 'Escull plantilla ',
					default: 'Per defecte',
					highlighted_users: 'Destaca jugadors',
				},
			},
			fileManager: {
				title: 'Gestor de Fitxers',
			},
			LibrariesManager: {
				title: 'Gestor de Llibreries',
				tabs: {
					public: 'Pública',
					private: 'Meva',
					rules: 'Regles',
				},
				content: {
					folders: 'Carpetes',
					files: 'Fitxers',
					createFolder: 'Crear Carpeta',
				},
				createFolderDialog: {
					title: 'Crear Carpeta',
					content: 'Escriu un nom per a la carpeta',
					placeholder: 'Nom de la carpeta',
					existsFolder: 'Aquesta carpeta ja existeix',
				},
				createLibraryDialog: {
					title: 'Crea una llibrería',
					content: 'Escriu un nom per a la llibreria',
					placeholder: 'Nom de la llibreria',
					exists: 'Aquesta llibreria ja existeix',
				},
				errors: {
					uploadFile: "Ups, no s'ha pogut desar {{filename}} ",
					deleteFile: "Ups, no s'ha pogut eliminar {{filename}} ",
				},
			},
			streamMenu: {
				remove: 'Eliminar jugador (Només per a mi)',
				resync: 'Reconectar',
			},
			chat: {
				warningSecretChat: 'En {{from}} ha enviat un missatge secret a en {{to}}',
				minimizedChat: {
					title: 'Chat',
				},
				chatContent: {
					allTitle: 'Tots els jugadors',
					placeholder: 'Escriu aquí',
					send: 'Enviar',
				},
			},
			countDown: {
				hours: 'Hores',
				minutes: 'Minuts',
				seconds: 'Segons',
			},
			dragnDrop: {
				text: 'Arrosega alguns fitxers aquí, o fes click al butó',
				button: 'Escull fitxer',
			},
		},
	},
};

const options = {
	order: ['querystring', 'navigator'],
	lookupQuerystring: 'lng',
};

i18n.use(initReactI18next)
	.use(LanguageDetector) // passes i18n down to react-i18next
	.init({
		resources,
		detection: options,
		fallbackLng: 'ca',
		supportedLngs: ['ca', 'es', 'en'],
	});

export default i18n;
