import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { DiceIcons, DiceResult } from './../../components';
import { DICE_TYPES } from './../../common/constants';
import {
	customDicesState,
	customDiceSelectedState,
	dicesBonusListState,
	dicesSelectedState,
	isNextDiceExplosiveState,
	isNextDiceRepetitionState,
	repeatDicesState,
} from '../../common/recoil/GameSystem/gameSystemAtom';
import { diceResultsState, diceRollState } from '../../common/recoil/UserInfo/atoms';

const useStyles = makeStyles((theme) => ({
	inlineDices: {
		position: 'absolute',
		'&::-webkit-scrollbar': {
			width: '0.4em',
			height: '0.1px',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.3)',
			outline: '1px solid rgba(255,255,255,.3)',
		},
		overflow: 'auto',
		height: '100%',
		display: 'flex',
		flexDirection: 'column-reverse',
	},

	dice: {
		background: "url('/images/d10.png') no-repeat center transparent",
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		height: '50px',
		width: '100px',
		backgroundSize: 'contain',
		color: 'white',
	},
	newDice: {
		position: 'relative',
	},
	clickable: {
		cursor: 'pointer',
	},
	notClickable: {
		cursor: 'arrow',
	},
	diceClicked: {
		backgroundColor: 'rgba(255, 255, 255, 0.3)!important',
	},
	newDiceSvg: {
		color: 'black',
	},
	newDiceSpan: {
		color: 'white',
		background: 'black',
		position: 'absolute',
		fontSize: '16px',
		left: '10px',
		top: '10px',
		width: '19px',
		textAlign: 'center',
		borderRadius: '50px',
	},
	customDice: {
		display: 'flex',
		background: 'white',
		color: 'black',
		justifyContent: 'center',
		alignItems: 'center',
		height: '40px',
		width: '40px',
		marginBottom: '5px',
	},
	diceNotation10: {
		clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0 51%)',
	},
	diceDifficultySuccess: {
		background: 'green',
	},
	diceDifficultyFail: {
		background: 'red',
	},
	diceDifficultyWarning: {
		background: 'orange',
	},
}));

const UserCameraCustomDices = ({ diceResults }) => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [customDices, setCustomDices] = useRecoilState(customDicesState);
	const [customDiceSelected, setCustomDiceSelected] = useRecoilState(customDiceSelectedState);
	const [diceSelected, setDiceSelected] = useRecoilState(dicesSelectedState);
	const [dicesBonusList, setDicesBonusList] = useRecoilState(dicesBonusListState);
	const [isNextDiceExplosive, setIsNextDiceExplosive] = useRecoilState(isNextDiceExplosiveState);
	const [isNextDiceRepetition, setIsNextDiceRepetition] =
		useRecoilState(isNextDiceRepetitionState);
	const [repeatDices, setRepeatDices] = useRecoilState(repeatDicesState);
	const [previousSnackBars, setPreviousSnackBars] = useState([]);
	const [roll, setRoll] = useRecoilState(diceRollState);

	useEffect(() => {
		previousSnackBars.forEach((key) => closeSnackbar(key));
		if (repeatDices.length > 0) {
			enqueueSnackbar(
				t('diceResults.repeat.title', {
					diceNumber: repeatDices.length,
				}),
				{
					variant: 'success',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					action: (key) => {
						setPreviousSnackBars([...previousSnackBars, key]);
						return (
							<Button
								onClick={() => {
									reroll(key);
								}}>
								Lanzar
							</Button>
						);
					},
				}
			);
		}
	}, [repeatDices]);

	useEffect(() => {
		setRepeatDices([]);
	}, [diceResults]);

	const currentCustomDiceConfig = useMemo(() => {
		return customDiceSelected ? customDices.find((x) => x.name === customDiceSelected) : [];
	}, [customDiceSelected]);

	const diceClicked = (result) => {
		if (result.repeatable) {
			if (repeatDices.some((dice) => dice.id === result.id)) {
				setRepeatDices([...repeatDices.filter((dice) => dice.id !== result.id)]);
			} else {
				setRepeatDices([...repeatDices, result]);
			}
		}
	};

	const reroll = (key) => {
		closeSnackbar(key);
		setIsNextDiceRepetition(true);
		setRoll(repeatDices.map((x) => x.notation));
	};

	const CommonDice = ({ id, name, result }) => {
		return (
			<Box
				className={`${classes.newDice} ${
					result.repeatable ? classes.clickable : classes.notClickable
				} `}
				key={name}
				onClick={() => diceClicked(result)}>
				<DiceIcons dice={result.notation} size="medium" />
				<span
					className={`${
						repeatDices.some((dice) => dice.id === id) ? classes.diceClicked : ''
					} ${classes.newDiceSpan}`}>
					<DiceResult
						notation={result.notation}
						result={result.value}
						type={result.type}
					/>
				</span>
			</Box>
		);
	};

	const CustomDice = ({ id, name, result }) => {
		const notationClass = `diceNotation${result.notation}`;
		const successClass = `diceDifficulty${
			result.success === false
				? result.partialSuccess === true
					? 'Warning'
					: 'Fail'
				: 'Success'
		}`;
		const customClasses = `${classes.customDice} ${classes[notationClass]} ${
			classes[successClass]
		} ${repeatDices.some((dice) => dice.id === id) ? classes.diceClicked : ''}`;
		return (
			<Box className={customClasses} key={name}>
				{result.value}
			</Box>
		);
	};

	const RenderDice = ({ name, result }) => {
		switch (result.type) {
			case DICE_TYPES.CUSTOM:
				return <CustomDice id={result.id} name={`custom-${name}`} result={result} />;
				break;
			default:
				return <CommonDice id={result.id} name={name} result={result} />;
		}
	};

	return (
		<>
			{diceResults?.map((result, index) => {
				if (result) {
					if (Array.isArray(result)) {
						return result.map((x, subIndex) => {
							return <RenderDice name={`dice-${index}-${subIndex}`} result={x} />;
						});
					} else if (result instanceof Object) {
						return <RenderDice name={`dice-${index}`} result={result} />;
					} else {
						return <Box className={classes.dice}> {result} </Box>;
					}
				}
			})}
		</>
	);
};

export default UserCameraCustomDices;
