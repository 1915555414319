export const FILE_MANAGER = {
	token: 'Pvz1bmckcp7BY2fXTW5Su8H7TPp6EWFF7haEqXXk',
	urls: {
		uploadImage: 'https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/imageUpload',
		getS3PresignedPost: (userId, extension) =>
			`https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/getS3PresignedPostUrl?userId=${userId}&extension=${extension}`,
		getSignedFile: (key) =>
			`https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/getSignedFile?key=${key}`,
		getLibraryFile: (key, userId) =>
			`https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/library?key=${key}${
				userId ? `&user_id=${userId}` : ''
			}`,
		createLibrary: 'https://8os6hpbvei.execute-api.eu-west-1.amazonaws.com/dev/library',
	},
};
export const LIBRARY_MANAGER = {
	v1: {
		token: 'NkscQ254D45FRWQlQc9cc45wrW3IJoBx1HRx6zi6',
		urls: {
			createLibrary: 'https://zl79sxq7fe.execute-api.eu-west-1.amazonaws.com/dev/libraries',
			getLibraries: (userId) =>
				`https://zl79sxq7fe.execute-api.eu-west-1.amazonaws.com/dev/libraries?userId=${userId}`,
		},
	},
	stg: {
		token: '1rO7miX0im3qfiEZdXGED8cZiN41SXnZ3q2R0Sb3',
		urls: {
			createLibrary: 'https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/libraries',
			deleteLibrary: (id) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/deleteLibrary/${id}`,
			deleteLibraryFile: (id, userId) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/deleteLibraryFile/${id}?userId=${userId}`,
			deleteLibraryFolder: (id, path) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/deleteLibraryFolder/${id}?path=${path}`,
			getLibraries: (userId) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/libraries?userId=${userId}`,
			getLibrary: (key, userId) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/library?key=${key}${
					userId ? `&user_id=${userId}` : ''
				}`,
			getFilePresignedPost: (userId, extension, hash, size) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/getFilePresignedPost?userId=${userId}&extension=${extension}&hash=${hash}&size=${size}`,
			getSignedFile: (key) =>
				`https://0qn2szgc34.execute-api.eu-west-1.amazonaws.com/stg/getSignedLibraryFile?key=${key}`,
		},
	},
	prod: {
		token: 'uqQMLl6pVB46ETa4nVblz2AxGxQ4Aq098RSD31Jv',
		urls: {
			createLibrary: 'https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/libraries',
			deleteLibrary: (id) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/deleteLibrary/${id}`,
			deleteLibraryFile: (id, userId) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/deleteLibraryFile/${id}?userId=${userId}`,
			deleteLibraryFolder: (id, path) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/deleteLibraryFolder/${id}?path=${path}`,
			getLibraries: (userId) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/libraries?userId=${userId}`,
			getLibrary: (key, userId) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/library?key=${key}${
					userId ? `&user_id=${userId}` : ''
				}`,
			getFilePresignedPost: (userId, extension, hash, size) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/getFilePresignedPost?userId=${userId}&extension=${extension}&hash=${hash}&size=${size}`,
			getSignedFile: (key) =>
				`https://txgbbr5ek0.execute-api.eu-west-1.amazonaws.com/prod/getSignedLibraryFile?key=${key}`,
		},
	},
};
export const AVATAR_MANAGER = {
	v1: {
		token: 'y3RIqMqLMT8GpUCEW7G986vQdUCMiMTM54TZKtpS',
		urls: {
			uploadAvatar: 'https://h5tvh2rbg8.execute-api.eu-west-1.amazonaws.com/dev/createAvatar',
			getUserAvatars: (userId) =>
				`https://h5tvh2rbg8.execute-api.eu-west-1.amazonaws.com/dev/avatars?userId=${userId}`,
			deleteAvatar: (id) =>
				`https://h5tvh2rbg8.execute-api.eu-west-1.amazonaws.com/dev/avatars/${id}`,
		},
	},
	stg: {
		token: 'H1iOIqR8To7PduTtYaG6q4vRhcE2gICN9DhXFl2X',
		urls: {
			uploadAvatar: 'https://pp8dsl316a.execute-api.eu-west-1.amazonaws.com/stg/createAvatar',
			getUserAvatars: (userId) =>
				`https://pp8dsl316a.execute-api.eu-west-1.amazonaws.com/stg/avatars?userId=${userId}`,
			deleteAvatar: (id) =>
				`https://pp8dsl316a.execute-api.eu-west-1.amazonaws.com/stg/avatars/${id}`,
		},
	},
	prod: {
		token: 'lHv7kpU5uP5x7hcjTADek1bcyRXEAvkT727Yek1M',
		urls: {
			uploadAvatar:
				'https://k1xsmjk7cj.execute-api.eu-west-1.amazonaws.com/prod/createAvatar',
			getUserAvatars: (userId) =>
				`https://k1xsmjk7cj.execute-api.eu-west-1.amazonaws.com/prod/avatars?userId=${userId}`,
			deleteAvatar: (id) =>
				`https://k1xsmjk7cj.execute-api.eu-west-1.amazonaws.com/prod/avatars/${id}`,
		},
	},
};
