import React, { memo, useEffect, useState } from "react";
import { Box, Fab } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  CameraLayout,
  Chat,
  Dices,
  GameControl,
  GameMenu,
  LibrariesManager,
  SharingArea,
  UserStream,
  VideoWebrtc,
} from "./../index";
import { CAMERA_TYPE } from "./../../common/constants";
import { isShareActiveSelector } from "./../../common/recoil/Share/selectors";
import { isChatOpenState } from "./../../common/recoil/chatMessages/atoms";
import {
  useAWSSocket,
  useCamera,
  useClientMessages,
  useShareMusic,
  useRoom,
  useStreamConnections,
} from "./../../common/hooks/index";
import { currentCameraLayoutState } from "../../common/recoil/Cameras/atom";
import { remoteDiceResultsState } from "../../common/recoil/chatMessages/atoms";
import {
  localStreamState,
  musicShareStreamState,
} from "../../common/recoil/Share/atom";
import { diceResultsState } from "../../common/recoil/UserInfo/atoms";
import {
  remoteMediaStreamsState,
  remoteNamesState,
} from "./../../common/recoil/websocket/atoms";

import "./Tabletop.scss";

const Tabletop = ({ client }) => {
  const [localVideoReady, setLocalVideoReady] = useState(false);
  const [musicReady, setMusicReady] = useState(false);
  const [isChatOpen, setIsChatOpen] = useRecoilState(isChatOpenState);

  //const [streamConnections, setStreamConnections] = useState([]);
  const [playersCountClass, setPlayersCountClass] = useState("single");

  const remoteDiceResults = useRecoilValue(remoteDiceResultsState);
  const streamConnections = useRecoilValue(remoteMediaStreamsState);
  const remoteNames = useRecoilValue(remoteNamesState);
  const isShareActive = useRecoilValue(isShareActiveSelector);
  const currentCameraLayout = useRecoilValue(currentCameraLayoutState);
  const musicShareStream = useRecoilValue(musicShareStreamState);
  const localMedia = useRecoilValue(localStreamState);
  const [diceResults, setDiceResults] = useRecoilState(diceResultsState);

  useCamera();
  const shareMusic = useShareMusic(localMedia);
  //const client = useAWSSocket(localMedia);
  // const id = useClientMessages(client);
  const id = 1;
  const refetchRoomUsers = useRoom(client, localMedia);
  //useStreamConnections(client, localMedia);

  useEffect(() => {
    document.addEventListener("refetchRoomUsers", function (e) {
      refetchRoomUsers();
    });
  }, []);

  useEffect(() => {
    if (localMedia) {
      setLocalVideoReady(true);
    }
  }, [localMedia]);

  useEffect(() => {
    if (musicShareStream) {
      setMusicReady(true);
    }
  }, [musicShareStream]);

  useEffect(() => {
    if (streamConnections) {
      if (0 < streamConnections.length && streamConnections.length <= 3) {
        setPlayersCountClass("middle");
      } else if (3 < streamConnections.length) {
        setPlayersCountClass("third");
      }
    }
  }, [streamConnections]);

  return (
    <div>
      {/* <GameMenu 
                streamConnections={streamConnections}
                //setStreamConnections={setStreamConnections}
                showSelector={showDiceSelector}
                setShowSelector={setShowDiceSelector}
                stream={localMedia}
                isChatOpen={isChatOpen}
                setIsChatOpen={setIsChatOpen} /> */}
      {currentCameraLayout.type === CAMERA_TYPE.DEFAULT && (
        <Box>
          <Box
            className={`cameraContainer ${playersCountClass} ${
              isShareActive ? "shareActive" : "shareInactive"
            }`}
          >
            {localVideoReady ? (
              <VideoWebrtc
                diceResults={diceResults}
                mediaStream={localMedia}
                ready={localVideoReady}
                userId={id || "local"}
                isLocalUser={true}
              />
            ) : null}

            {streamConnections.map((remoteStreamConnection) => {
              return (
                <VideoWebrtc
                  diceResults={
                    remoteDiceResults.find(
                      (x) => x.id === remoteStreamConnection.id
                    )?.results || []
                  }
                  players={streamConnections.length + 2}
                  key={remoteStreamConnection.id}
                  mediaStream={remoteStreamConnection.streamMedia}
                  userId={remoteStreamConnection.id}
                  remoteUserName={
                    remoteNames.find((x) => x.id === remoteStreamConnection.id)
                      ?.name || remoteStreamConnection.id
                  }
                  ready={true}
                />
              );
            })}
          </Box>
          <Box
            className={`shareContainer ${
              isShareActive ? "shareActive" : "shareInactive"
            }`}
          >
            <SharingArea></SharingArea>
          </Box>
        </Box>
      )}
      {currentCameraLayout.type === CAMERA_TYPE.HIGHLIGHTED_USERS && (
        <CameraLayout localMedia={localMedia}></CameraLayout>
      )}
      <Chat
        diceResults={diceResults}
        id={id}
        isOpen={isChatOpen}
        setIsOpen={setIsChatOpen}
      ></Chat>
      <Dices diceResults={diceResults} setDiceResults={setDiceResults}></Dices>

      <React.Suspense fallback={<div>Loading...</div>}>
        <LibrariesManager />
      </React.Suspense>
      {/* <p className="floating-text">
                There are { roomConnections.length } clients.
                There are { streamConnections.length } streams.
            </p>             */}
      {/* <Box position="fixed" height="300px" width="300px">
				<VideoWebrtc
					diceResults={diceResults}
					mediaStream={musicShareStream}
					ready={musicReady}
					userId={'music'}
					isLocalUser={false}
				/>
			</Box> */}
    </div>
  );
};

export default memo(Tabletop);
