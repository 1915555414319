import { selector } from 'recoil';
import { Auth } from 'aws-amplify';

import {
	currentLibraryBreadCrumbState,
	currentLibraryTypeState,
	forceLibraryUpdate,
	publicLibrariesState,
	privateLibrariesState,
	publicLibrariesContentState,
	privateLibrariesContentState,
	rulesLibraryState,
	rulesLibraryContentState,
} from './libraryAtom';

import { getLibrary } from '../../services/libraryRequests';

import { EMPTY_LIBRARY, LIBRARY_TYPE, SLEEP } from './../../constants';

const getLibraryContent = async (name, libraries, librariesContent, isPublic, isRule) => {
	const library = libraries.find((x) => x.name === name);

	let libraryContent = librariesContent.find((x) => x.name === name);
	if (library?.dirty && !libraryContent) {
		const currentUserInfo = await Auth.currentUserInfo();

		libraryContent = { ...EMPTY_LIBRARY };
		libraryContent.name = name;
		libraryContent.userId = currentUserInfo.username;
	} else if (!library?.dirty && !libraryContent && library) {
		libraryContent = await getLibrary(library.ID, isPublic, isRule);
		libraryContent.key = library.ID;
	}
	return libraryContent;
};

export const currentLibraryContentSelector = selector({
	key: 'currentLibraryContentSelector',
	get: async ({ get }) => {
		const currentBreadcrumb = get(currentLibraryBreadCrumbState);
		const libraryUpdate = get(forceLibraryUpdate);
		let currentLibaryContent = [];
		if (currentBreadcrumb && currentBreadcrumb?.length > 0) {
			const libraryName = currentBreadcrumb[0];

			if (get(currentLibraryTypeState) === LIBRARY_TYPE.PUBLIC) {
				currentLibaryContent = await getLibraryContent(
					libraryName,
					get(publicLibrariesState),
					get(publicLibrariesContentState),
					true,
					false
				);
			} else if (get(currentLibraryTypeState) === LIBRARY_TYPE.PRIVATE) {
				currentLibaryContent = await getLibraryContent(
					libraryName,
					get(privateLibrariesState),
					get(privateLibrariesContentState),
					false,
					false
				);
			} else {
				currentLibaryContent = await getLibraryContent(
					libraryName,
					get(rulesLibraryState),
					get(rulesLibraryContentState),
					true,
					true
				);
			}
		}
		return currentLibaryContent;
	},
	set: ({ get, set }, newElement) => {
		let tempCurrentLibaryContent = get(currentLibraryContentSelector);
		let tempLibariesContent;
		// if (get(currentLibraryTypeState) === LIBRARY_TYPE.PUBLIC) {

		// } else {
		// }

		const newLibraryChildren = [
			...tempCurrentLibaryContent.children.filter((x) => x.name !== newElement.name),
			newElement,
		];

		let newCurrentLibraryContent = {
			...tempCurrentLibaryContent,
		};
		newCurrentLibraryContent.children = newElement.children;

		if (get(currentLibraryTypeState) === LIBRARY_TYPE.PUBLIC) {
			const publicLibrariesContent = get(publicLibrariesContentState);

			tempLibariesContent = [
				...publicLibrariesContent.filter((x) => x.name !== newCurrentLibraryContent.name),
				newElement,
			];
			set(publicLibrariesContentState, tempLibariesContent);
		} else if (get(currentLibraryTypeState) === LIBRARY_TYPE.PRIVATE) {
			const privateLibrariesContent = get(privateLibrariesContentState);

			tempLibariesContent = [
				...privateLibrariesContent.filter((x) => x.name !== newCurrentLibraryContent.name),
				newElement,
			];
			set(privateLibrariesContentState, tempLibariesContent);
		} else {
			const rulesLibraryContent = get(rulesLibraryContentState);

			tempLibariesContent = [
				...rulesLibraryContent.filter((x) => x.name !== newCurrentLibraryContent.name),
				newElement,
			];
			set(rulesLibraryContentState, tempLibariesContent);
		}
		const libraryUpdate = get(forceLibraryUpdate);
		set(forceLibraryUpdate, libraryUpdate + 1);
		// const current = get(uploadedImagesState);
		// const newValue = [newElement, ...current];
		// set(uploadedImagesState, newValue);
	},
});

export const currentFolderSelector = selector({
	key: 'currentFolderSelector',
	get: async ({ get }) => {
		const currentBreadcrumb = get(currentLibraryBreadCrumbState) || [];
		const currentLibraryContent = get(currentLibraryContentSelector);
		let folder = currentLibraryContent;
		for (let i = 1; i < currentBreadcrumb.length; i++) {
			folder = folder.children.find((x) => x.name === currentBreadcrumb[i]);
		}
		return folder;
	},
});
