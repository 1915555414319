import React, { useState } from 'react';
import { Badge, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, CreateNewFolder, Folder, InsertDriveFile } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { showErrorTooltip } from '../../../common/services';
import { deleteFile, deleteFolder, deleteLibrary } from '../../../common/services/libraryRequests';
import { findAndDeleteElement } from '../../../common/services/libraryUtils';
import { CHAT_EVENTS, LIBRARY_TYPE, LIBRARY_ITEMS_TYPES } from '../../../common/constants';
import {
	currentLibraryTypeState,
	currentLibraryBreadCrumbState,
	showCreateFolderDialogState,
	showCreateLibraryDialogState,
	showLibrariesManagerState,
	privateLibrariesState,
	publicLibrariesState,
	rulesLibraryState,
} from '../../../common/recoil/Library/libraryAtom';
import { currentLibraryContentSelector } from '../../../common/recoil/Library/librarySelector';
import {
	ruleImageState,
	shareCanvasImgState,
	sharedAudiosState,
	sharedImageState,
} from '../../../common/recoil/Share/atom';
import { useChat } from '../../../common/hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		cursor: 'pointer',
		overflow: 'hidden',
	},
	paperIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	img: {
		maxWidth: '100%',
	},
	close: {
		margin: 0,
		padding: 0,
		cursor: 'pointer',
	},
	title: {
		wordBreak: 'break-all',
	},
}));

const StyledBadge = withStyles((theme) => ({
	badge: {
		display: 'block',
		right: 4,
		top: 8,
		border: `0px solid ${theme.palette.background.paper}`,
		padding: '0',
	},
}))(Badge);

const LibraryItem = ({ file, isLibraryLevel, title, type }) => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [showCreateLibraryDialog, setShowCreateLibraryDialog] = useRecoilState(
		showCreateLibraryDialogState
	);
	const [showCreateFolderDialog, setShowCreateFolderDialog] = useRecoilState(
		showCreateFolderDialogState
	);
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [showLibrariesManager, setShowLibrariesManager] =
		useRecoilState(showLibrariesManagerState);
	const [sharedImage, setSharedImage] = useRecoilState(sharedImageState);
	const [ruleImage, setRuleImage] = useRecoilState(ruleImageState);
	const [sharedAudios, setSharedAudios] = useRecoilState(sharedAudiosState);
	const [shareCanvasImg, setShareCanvasImg] = useRecoilState(shareCanvasImgState);
	const currentLibraryType = useRecoilValue(currentLibraryTypeState);
	const [currentLibraryContent, setCurrentLibraryContent] = useRecoilState(
		currentLibraryContentSelector
	);
	const [privateLibraries, setPrivateLibraries] = useRecoilState(privateLibrariesState);
	const [publicLibraries, setPublicLibraries] = useRecoilState(publicLibrariesState);
	const [rulesLibrary, setRulesLibrary] = useRecoilState(rulesLibraryState);

	const { messages, sendMessage } = useChat();

	const isCreateFolder = type === LIBRARY_ITEMS_TYPES.CREATE_FOLDER;
	const isFolder = type === LIBRARY_ITEMS_TYPES.FOLDER || type === undefined;
	const isFile = type === LIBRARY_ITEMS_TYPES.FILE;
	const isPrivate = currentLibraryType === LIBRARY_TYPE.PRIVATE;
	const isPublic = currentLibraryType === LIBRARY_TYPE.PUBLIC;
	const isRule = currentLibraryType === LIBRARY_TYPE.RULES;

	const handleDelete = async () => {
		if (isLibraryLevel) {
			await deleteLibrary(file.ID || file.key);
			if (isPrivate) {
				const newLibraryEntry = [...privateLibraries.filter((x) => x.name !== title)];
				setPrivateLibraries(newLibraryEntry);
			} else if (isPublic) {
				const newLibraryEntry = [...publicLibraries.filter((x) => x.name !== title)];
				setPublicLibraries(newLibraryEntry);
			} else {
				const newLibraryEntry = [...rulesLibrary.filter((x) => x.name !== title)];
				setRulesLibrary(newLibraryEntry);
			}
		} else if (isFile || isFolder) {
			removeFromFolder();
		}
	};

	const handleClick = () => {
		if (isCreateFolder) {
			createNew();
		} else if (isFile) {
			toggleElement();
		} else {
			const previousBreadCrumb = [...currentLibraryBreadCrumb];
			const nextBreadCrumb = [...previousBreadCrumb, title];
			setCurrentLibraryBreadCrumb(nextBreadCrumb);
		}
	};

	const toggleElement = () => {
		if (file.isImage) {
			if (file?.key === sharedImage?.key) {
				stopShareImage();
			} else {
				if (currentLibraryType === LIBRARY_TYPE.RULES) {
					if (file.key === ruleImage) {
						setRuleImage(null);
					} else {
						setRuleImage(file.url);
						setShowLibrariesManager(false);
					}
				} else {
					setSharedImage(file);
					setShareCanvasImg(file.url);
					const sharefileMessage = {
						type: CHAT_EVENTS.SHARE_S3_IMAGE,
						img: file.url,
					};
					sendMessage(sharefileMessage);
				}
			}
		} else if (file.isAudio) {
			const tempAudios = [...sharedAudios];
			if (!sharedAudios.some((x) => x.key === file.key)) {
				tempAudios.push(file);
				setSharedAudios(tempAudios);
				const sharefileMessage = {
					type: CHAT_EVENTS.SHARE_S3_AUDIO,
					sharedAudio: tempAudios,
				};
				sendMessage(sharefileMessage);
			}
		}
	};

	const stopShareImage = () => {
		setShareCanvasImg(null);
		setSharedImage(null);
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_IMAGE,
			img: null,
		};
		sendMessage(shareImgMessage);
	};

	const createNew = () => {
		if (isLibraryLevel) {
			setShowCreateLibraryDialog(true);
		} else {
			setShowCreateFolderDialog(true);
		}
	};

	const showCreateNewFolder = () => {
		return (
			<IconButton size="large">
				<CreateNewFolder />
			</IconButton>
		);
	};
	const showFolderIcon = () => {
		return (
			<IconButton size="large">
				<Folder />
			</IconButton>
		);
	};

	const renderFile = () => {
		if (file.isImage) {
			return <img className={classes.img} src={file.url} alt={file.name} />;
		} else {
			return <InsertDriveFile></InsertDriveFile>;
		}
	};

	const customPaper = () => {
		return (
			<Paper
				onClick={handleClick}
				className={`${classes.paper} ${
					isCreateFolder || isFolder ? classes.paperIcon : ''
				}`}>
				{isFolder && showFolderIcon()}
				{isFile && renderFile()}
				<Tooltip className={classes.title} title={title}>
					<Typography>{title}</Typography>
				</Tooltip>
				{isCreateFolder && showCreateNewFolder()}
			</Paper>
		);
	};

	const showError = async () => {
		showErrorTooltip(
			enqueueSnackbar,
			closeSnackbar,
			t('LibrariesManager.errors.deleteFile', { filename: file.name })
		);
	};

	const removeFromFolder = async () => {
		let currentLibrary = { ...currentLibraryContent, dirty: true };
		currentLibrary.children = [...currentLibrary.children];
		let response;
		if (isFile) {
			response = await deleteFile(file.hash);
		} else if (isFolder) {
			const tempBreadCrumb = [...currentLibraryBreadCrumb, file.name];
			const path = tempBreadCrumb.slice(1).join('/');
			response = await deleteFolder(currentLibrary.key, path);
		}
		if (response.status === 200) {
			currentLibrary = findAndDeleteElement(
				currentLibrary,
				currentLibraryBreadCrumb.slice(1),
				file.name,
				showError,
				LIBRARY_ITEMS_TYPES.FILE,
				file
			);
			setCurrentLibraryContent(currentLibrary);
		} else {
			console.log('error deleting');
			//showError();
		}
	};

	return (
		<>
			{isFolder || isFile ? (
				<StyledBadge
					badgeContent={
						<Cancel
							className={classes.close}
							onClick={() => {
								handleDelete();
							}}></Cancel>
					}
					color="secondary">
					{customPaper()}
				</StyledBadge>
			) : (
				customPaper()
			)}
		</>
	);
};

export default LibraryItem;
