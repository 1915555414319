import { selector } from 'recoil';
import PubSub from 'pubsub-js';

import { chatmessagesState, remoteDiceResultsState, remoteChatState } from './atoms';
import { currentCameraLayoutState } from './../Cameras/atom';
import {
	alreadySharedImageIdsState,
	sharedAudiosState,
	shareCanvasImgState,
	shareCanvasImgTempState,
	shareCountDownTimeState,
} from './../Share/atom';
import {
	remoteAvatarsState,
	roomConnectionsState,
	remoteNamesState,
	remoteVideosPlayingState,
	remoteAudiosPlayingState,
} from './../websocket/atoms';
import { remoteMediaStreamsSelector, removeMediaStreamsSelector } from './../websocket/selectors';

import { CHAT_EVENTS, CLIENT_EVENTS, COUNTDOWN_CAMERA } from './../../constants';

export const chatMessagesSelector = selector({
	key: 'ChatMessagesSelector',
	get: ({ get }) => get(chatmessagesState),
	set: ({ get, set }, remoteChat) => {
		try {
			console.log('detected change on chat message selector', remoteChat);
			const remoteChatObject = JSON.parse(remoteChat);
			switch (remoteChatObject.type) {
				case CHAT_EVENTS.DICE_RESULT:
					let current = get(remoteDiceResultsState);
					current = current.filter((x) => x.id !== remoteChatObject.id);
					const newValue = [
						{
							id: remoteChatObject.id,
							results: remoteChatObject.value,
						},
						...current,
					];
					set(remoteDiceResultsState, newValue);
					break;
				case CHAT_EVENTS.CLEAR_DICE_RESULTS:
					const currentResults = get(remoteDiceResultsState);
					set(
						remoteDiceResultsState,
						currentResults.filter((x) => x.id !== remoteChatObject.id)
					);
					break;
				case CHAT_EVENTS.SHARING:
					if (remoteChatObject.complete) {
						const image = get(shareCanvasImgTempState);
						set(shareCanvasImgState, image);
						set(shareCanvasImgTempState, '');
						set(alreadySharedImageIdsState, remoteChatObject.sharedUsers);
					} else if (remoteChatObject.finished) {
						set(shareCanvasImgState, null);
					} else {
						const tempImage = get(shareCanvasImgTempState);
						set(shareCanvasImgTempState, tempImage + remoteChatObject.img);
					}

					break;
				case CHAT_EVENTS.USER_NAME:
					const currentNames = get(remoteNamesState);

					if (remoteChatObject.name !== '') {
						const newName = {
							id: remoteChatObject.id,
							name: remoteChatObject.name,
						};
						set(remoteNamesState, [
							newName,
							...currentNames.filter((x) => x.id !== remoteChatObject.id),
						]);
					}

					break;
				case CHAT_EVENTS.AVATAR:
					const currentAvatars = get(remoteAvatarsState);
					const newAvatar = {
						id: remoteChatObject.id,
						avatarUrl: remoteChatObject?.avatar?.url,
					};
					set(remoteAvatarsState, [
						newAvatar,
						...currentAvatars.filter((x) => x.id !== remoteChatObject.id),
					]);

					break;
				case CHAT_EVENTS.VIDEO_STATUS:
					const currentVideosStatus = get(remoteVideosPlayingState);
					if (remoteChatObject?.isLocalVideoPlaying !== undefined) {
						const newVideoStatus = {
							id: remoteChatObject.id,
							isLocalVideoPlaying: remoteChatObject?.isLocalVideoPlaying,
						};
						set(remoteVideosPlayingState, [
							newVideoStatus,
							...currentVideosStatus.filter((x) => x.id !== remoteChatObject.id),
						]);
					}
					break;
				case CHAT_EVENTS.MIC_STATUS:
					const currentMicStatus = get(remoteAudiosPlayingState);
					if (remoteChatObject?.isMicPlaying !== undefined) {
						const newMicStatus = {
							id: remoteChatObject.id,
							isMicPlaying: remoteChatObject?.isMicPlaying,
						};
						set(remoteAudiosPlayingState, [
							newMicStatus,
							...currentMicStatus.filter((x) => x.id !== remoteChatObject.id),
						]);
					}

					break;
				case CHAT_EVENTS.CAMERA_LAYOUT:
					set(currentCameraLayoutState, remoteChatObject.layout);
					break;
				case CHAT_EVENTS.SHARE_S3_IMAGE:
					set(shareCanvasImgState, remoteChatObject.img);
					break;
				case CHAT_EVENTS.SHARE_S3_AUDIO:
					set(sharedAudiosState, remoteChatObject.sharedAudio);
					break;
				case CHAT_EVENTS.COUNTDOWN.START:
					set(shareCountDownTimeState, parseInt(remoteChatObject.value));
					set(remoteMediaStreamsSelector, {
						id: COUNTDOWN_CAMERA,
						streamMedia: {
							type: COUNTDOWN_CAMERA,
						},
					});
					break;
				case CHAT_EVENTS.COUNTDOWN.END:
					set(shareCountDownTimeState, 0);
					break;
				case CHAT_EVENTS.CHAT:
					const chatmessages = get(chatmessagesState);
					set(chatmessagesState, [...chatmessages, remoteChatObject]);
					break;
				case CHAT_EVENTS.KILL:
					console.log('!!!!!!!!!!KILL!');
					console.group();
					console.log('should delete', remoteChatObject);
					PubSub.publish(CLIENT_EVENTS.DELETE_USER, remoteChatObject.id);
					const currentRoomConnection = get(roomConnectionsState);
					console.log('currentRoomConnection', [...currentRoomConnection]);
					const tempRoomConnection = currentRoomConnection.filter(
						(x) => x.id !== remoteChatObject.id
					);
					set(roomConnectionsState, tempRoomConnection);
					console.log('end  room connections ', [...tempRoomConnection]);

					console.groupEnd();
					break;
				default:
					break;
			}
		} catch (e) {
			console.log('chat element not an object', remoteChat);
			console.log('error', e);
		}
	},
});

export const remoteChatSelector = selector({
	key: 'RemoteChatSelector',
	get: ({ get }) => get(remoteChatState),
	set: ({ get, set }, remoteStream) => {
		const current = get(remoteChatState);
		if (!current.some((x) => x.id === remoteStream.id)) {
			const newValue = [remoteStream, ...current];
			set(remoteChatState, newValue);
		} else {
			console.log('datacahennel already created');
		}
	},
});

export const removeChatChannelState = selector({
	key: 'RemoveChatChannelState',
	get: ({ get }) => get(remoteChatState),
	set: ({ get, set }, id) => {
		const current = get(remoteChatState);
		const newValue = current.filter((x) => x.id !== id);
		set(remoteChatState, newValue);
	},
});

const parseChatMessage = (remoteChat) => {};
