import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { grey } from '@mui/material/colors/';
import {
	AppBar,
	Backdrop,
	Button,
	CircularProgress,
	Drawer,
	IconButton,
	Toolbar,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack, Close, Save } from '@mui/icons-material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { LIBRARY_TYPE } from '../../common/constants';
import { saveLibrary } from '../../common/services/libraryUtils';
import {
	currentLibraryBreadCrumbState,
	privateLibrariesState,
	publicLibrariesContentState,
	publicLibrariesState,
	showLibrariesManagerState,
	uploadingLibraryFileState,
	privateLibrariesContentState,
	rulesLibraryState,
} from '../../common/recoil/Library/libraryAtom';
import Libraries from './components/Libraries';
import { currentLibraryTypeState } from '../../common/recoil/Library/libraryAtom';
import { currentLibraryContentSelector } from '../../common/recoil/Library/librarySelector';

const useStyles = makeStyles((theme) => ({
	drawer: {
		left: '100px',
		'& .MuiPaper-root': {
			left: '73px',
		},
	},
	root: {
		minWidth: '500px',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbar: {
		justifyContent: 'space-between',
	},
}));

const LibrariesManager = () => {
	const classes = useStyles();
	const [showLibrariesManager, setShowLibrariesManager] =
		useRecoilState(showLibrariesManagerState);
	const [uploadingFile, setUploadingFile] = useRecoilState(uploadingLibraryFileState);
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [currentLibraryContent, setCurrentLibraryContent] = useRecoilState(
		currentLibraryContentSelector
	);
	const [privateLibraries, setPrivateLibraries] = useRecoilState(privateLibrariesState);
	const [publicLibraries, setPublicLibraries] = useRecoilState(publicLibrariesState);
	const [rulesLibrary, setRulesLibrary] = useRecoilState(rulesLibraryState);
	const [publicLibrariesContent, setPublicLibrariesContent] = useRecoilState(
		publicLibrariesContentState
	);
	const [privateLibrariesContent, setPrivateLibrariesContent] = useRecoilState(
		privateLibrariesContentState
	);

	const currentLibraryType = useRecoilValue(currentLibraryTypeState);

	const { t, i18n } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		if (currentLibraryContent) {
			const isPrivate = currentLibraryType === LIBRARY_TYPE.PRIVATE;
			if (isPrivate) {
				const library = privateLibrariesContent.some(
					(x) => x.name === currentLibraryContent.name
				);
				if (!library) {
					setPrivateLibrariesContent([...privateLibrariesContent, currentLibraryContent]);
				}
			} else {
				const library = publicLibrariesContent.some(
					(x) => x.name === currentLibraryContent.name
				);

				if (!library) {
					setPublicLibrariesContent([...publicLibrariesContent, currentLibraryContent]);
				}
			}
			if (currentLibraryContent.dirty) {
				saveCurrentLibrary();
			}
		}
	}, [currentLibraryContent]);

	const onGoBack = () => {
		const tempBreadCrumb = [...currentLibraryBreadCrumb];
		tempBreadCrumb.pop();
		setCurrentLibraryBreadCrumb(tempBreadCrumb);
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const showSuccess = (message) => {
		enqueueSnackbar(message, {
			variant: 'success',
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			action: (key) => (
				<Button onClick={onClickDismiss(key)}>
					<Close color={'white'}></Close>
				</Button>
			),
			preventDuplicate: true,
		});
	};

	const saveCurrentLibrary = async () => {
		const currentUserInfo = await Auth.currentUserInfo();

		const savedLibrary = await saveLibrary(
			currentLibraryType,
			privateLibraries,
			currentLibraryContent,
			publicLibraries,
			rulesLibrary,
			currentUserInfo,
			setPrivateLibraries,
			setPublicLibraries,
			setRulesLibrary
		);
		const tempCurrentLibrary = {
			...currentLibraryContent,
			dirty: false,
			key: savedLibrary.key,
		};
		setCurrentLibraryContent(tempCurrentLibrary);
		showSuccess('Ok');
	};

	return (
		<>
			{showLibrariesManager && (
				<Drawer
					anchor="left"
					className={classes.drawer}
					open={showLibrariesManager}
					variant="permanent"
					onClose={() => setShowLibrariesManager(false)}>
					<AppBar position="static">
						<Toolbar className={classes.toolbar}>
							{currentLibraryBreadCrumb?.length > 0 && (
								<IconButton
									edge="start"
									className={classes.menuButton}
									color="inherit"
									onClick={onGoBack}
									aria-label="back"
									size="large">
									<ArrowBack />
								</IconButton>
							)}
							<Typography variant="h6">{t('LibrariesManager.title')}</Typography>
							<IconButton
								edge="start"
								className={classes.menuButton}
								color="inherit"
								onClick={saveCurrentLibrary}
								aria-label="back"
								size="large">
								<Save />
							</IconButton>
							<IconButton
								edge="end"
								className={classes.menuButton}
								color="inherit"
								onClick={() => setShowLibrariesManager(false)}
								aria-label="close"
								size="large">
								<Close />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Libraries />
					<Backdrop className={classes.backdrop} open={uploadingFile}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</Drawer>
			)}
		</>
	);
};

export default LibrariesManager;
