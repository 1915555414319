import React, { useEffect, useState } from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { SnackbarProvider } from 'notistack';
import { RecoilRoot } from 'recoil';
import WebSocketClient from 'websocket';
import PubSub from 'pubsub-js';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import makeStyles from '@mui/styles/makeStyles';

import './App.css';
import '@aws-amplify/ui-react/styles.css';

import { CLIENT_EVENTS, USER_STREAM_STATUS } from './common/constants';
import awsconfig from './common/aws-exports';
import UserStream from './common/services/userStream';
import { Game, Home, Main } from './pages/index';

Amplify.configure(awsconfig);

const theme = createTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#3a5a40',
			light: '#588157',
			dark: '#344e41',
		},
		secondary: {
			main: '#dda15e',
		},
	},
});

const useStyles = makeStyles((theme) => {
	root: {
		// some CSS that access to theme
	}
});

// let client = new WebSocketClient.w3cwebsocket(
// 	'wss://j0bip9ycyh.execute-api.eu-west-1.amazonaws.com/dev'
// );
let roomConnections = [];

function App() {
	const [client, setWebsocket] = useState({});
	const [id, setId] = useState(null);
	const [socketUrl, setSocketUrl] = useState();
	const [localMedia, setMedia] = useState();

	const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl, {
		onOpen: () => {
			setWebsocket({ send: sendJsonMessage });
			setInterval(() => {
				ping();
			}, 60 * 1000 * 7);
			console.log('localMedia', localMedia);
			console.log('location', location.pathname);
			console.log(
				'location.pathname !== gameSessionPath',
				location.pathname !== gameSessionPath
			);
			if (location.pathname === gameSessionPath) {
				sendJsonMessage({
					action: 'room',
					username: 'username',
					message: 'some weird message',
				});
			}
		},
		//Will attempt to reconnect on all close events, such as server shutting down
		shouldReconnect: (closeEvent) => true,
	});

	const ping = () => {
		sendJsonMessage({
			action: 'ping',
		});
	};

	useEffect(() => {
		const addClients = (newClients, myId, status = 'pasive', data = undefined) => {
			console.log('roomConnections has', roomConnections);
			const newClientStatus = newClients
				.filter((remoteId) => !roomConnections.some((x) => x.id === remoteId))
				.map((remoteId) => {
					return {
						id: remoteId,
						status: status,
						initialData: data,
						fromId: myId,
					};
				});
			setRoomConections(newClientStatus);
		};

		if (lastJsonMessage) {
			console.log('received lastJsonMessage', lastJsonMessage);
			if (lastJsonMessage.event === 'room-update') {
				console.log('my id is', lastJsonMessage.id);
				setId(lastJsonMessage.id);
				const clientsButMe = lastJsonMessage.clients.filter(
					(x) => x !== lastJsonMessage.id
				);
				addClients(clientsButMe, lastJsonMessage.id);
			} else if (lastJsonMessage.action === 'signaling') {
				if (lastJsonMessage.type === 'ping') {
					addClients(
						[lastJsonMessage.fromId],
						lastJsonMessage.id,
						'active',
						lastJsonMessage
					);
				} else {
					console.group('!!!!!!!!!!!!!!!!SIGNALING MESSSAGE');
					console.log('from id is', lastJsonMessage.fromId);
					console.log('type is', lastJsonMessage.type);
					console.log('full message is', lastJsonMessage);
					console.log('roomConnections are', roomConnections);
					const user = roomConnections.find((x) => x.id === lastJsonMessage.fromId);
					console.log('the user found is', user);
					console.log(
						'the user has method gotMessageFromSignaling',
						user.gotMessageFromSignaling
					);
					user.gotMessageFromSignaling('message', lastJsonMessage);
					console.groupEnd();
				}
			}
		}
	}, [lastJsonMessage]);

	const gameSessionPath = '/game';

	let mediaStreams = [];

	const addStream = (remoteId, stream) => {
		mediaStreams.push({
			id: remoteId,
			streamMedia: stream,
		});
		var event = new CustomEvent('newStreamMedia', {
			detail: {
				id: remoteId,
				streamMedia: stream,
			},
		});
		document.dispatchEvent(event);
	};

	const addDataChannel = (remoteId, channel) => {
		console.log('received dataChannel', channel, remoteId);
		var event = new CustomEvent('newDataChannel', {
			detail: {
				id: remoteId,
				channel: channel,
			},
		});
		document.dispatchEvent(event);
	};

	const removeDataChannel = (remoteId) => {
		var event = new CustomEvent('removeDataChannel', {
			detail: {
				id: remoteId,
			},
		});
		document.dispatchEvent(event);
	};

	const addNewRemoteMessage = (remoteId, message) => {
		console.log('received message', message, remoteId);
		var event = new CustomEvent('newDataChannelMessage', {
			detail: {
				id: remoteId,
				message: message,
			},
		});
		document.dispatchEvent(event);
	};

	const removeStream = (remoteId) => {
		var event = new CustomEvent('removeStream', {
			detail: {
				id: remoteId,
			},
		});
		document.dispatchEvent(event);
	};
	const refetchRoomUsers = (remoteId) => {
		var event = new CustomEvent('refetchRoomUsers', {
			detail: {
				id: remoteId,
			},
		});
		document.dispatchEvent(event);
	};

	const setLocalMedia = (media) => {
		if (!socketUrl) {
			console.log('set socket url');
			if (process.env.REACT_APP_API_ENV === 'prod') {
				setSocketUrl('wss://jdr4lgovx3.execute-api.eu-west-1.amazonaws.com/prod');
			} else {
				setSocketUrl('wss://luaw2cnx3b.execute-api.eu-west-1.amazonaws.com/stg');
			}
		}
		roomConnections.map((x) => x.updateLocalMedia(media));
		setMedia(media);
	};

	const setRoomConections = (newRoomConnection) => {
		if (roomConnections.length > 0) {
			//debugger;
		}

		if (localMedia && newRoomConnection.length > 0) {
			console.log('lets work with newroomconection', newRoomConnection);
			console.log('my id is', id);
			roomConnections
				.filter((oldConnection) =>
					newRoomConnection.some((x) => {
						return (
							oldConnection.status === USER_STREAM_STATUS.CREATED &&
							x.id === oldConnection.id
						);
					})
				)
				.forEach((x) => {
					console.log('should pong x', x);
					x.pong();
				});
			newRoomConnection
				.filter((x) => !roomConnections.some((oldConnection) => oldConnection.id === x.id))
				.map((newConnection) => {
					console.log('lets work with newCOnnection', newConnection);
					const newConnectionStream = new UserStream({
						client: client,
						id: newConnection.fromId || id,
						remoteId: newConnection.id,
						status: newConnection.status,
						initialData: newConnection.initialData,
						addStream: addStream,
						addDataChannel: addDataChannel,
						addNewRemoteMessage: addNewRemoteMessage,
						removeStream: removeStream,
						removeDataChannel: removeDataChannel,
						localMedia: localMedia,
					});
					roomConnections.push(newConnectionStream);
					return roomConnections;
				});
		}
	};

	const retrieveLogs = (ids = []) => {
		return roomConnections
			.filter((x) => ids.length === 0 || ids.includes(x.id))
			.map((x) => {
				return { id: x.id, logs: x.logs };
			});
	};

	const resyncUser = (event, id) => {
		const tempStream = roomConnections.find((x) => {
			return x.id === id;
		});

		tempStream.createStream();
		tempStream.setDataChannel();
		tempStream.resync();
	};

	const deleteUser = (event, id) => {
		const tempStream = roomConnections.find((x) => {
			return x.id === id;
		});

		try {
			tempStream.delete();
		} catch (e) {
			console.error('error', e);
		}

		roomConnections = roomConnections.filter((x) => {
			return x.id !== id;
		});

		mediaStreams = mediaStreams.filter((x) => {
			return x.id !== id;
		});
		removeStream(id);
		setTimeout(refetchRoomUsers, 5000);
	};

	let location = useLocation();

	useEffect(() => {
		if (location.pathname !== gameSessionPath) {
			clearRoomConnections();
			if (client.readyState === 1) {
				client.send(
					JSON.stringify({
						action: 'leaveRoom',
					})
				);
			}
		} else if (client) {
			console.log('sendJsonMessage is ', sendJsonMessage);
		}
	}, [location]);

	const clearRoomConnections = () => {
		roomConnections.map((x) => {
			x.delete();

			x = null;
		});

		while (roomConnections.length) {
			roomConnections.pop();
		}
		if (client.readyState === 1) {
			client.close();
		}
	};

	PubSub.subscribe(CLIENT_EVENTS.RESYNC_USER, resyncUser);
	PubSub.subscribe(CLIENT_EVENTS.DELETE_USER, deleteUser);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<RecoilRoot>
					<SnackbarProvider maxSnack={7} preventDuplicate>
						<Routes>
							<Route path="/" element={<Main />}>
								<Route index element={<Home />} />
								<Route path=":teamId" element={3} />
								<Route path="new" element={4} />
							</Route>
							<Route
								path="game"
								element={
									<Game
										client={client}
										retrieveLogs={retrieveLogs}
										setId={setId}
										id={id}
										setLocalMedia={setLocalMedia}
										setRoomConections={setRoomConections}
										resyncUser={resyncUser}
									/>
								}></Route>
						</Routes>
					</SnackbarProvider>
				</RecoilRoot>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default withAuthenticator(App);
