import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
	Avatar,
	Badge,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { ConfirmDialog, DropZone } from './../../common/components';
import { userAvatarState, userNameState } from './../../common/recoil/UserInfo/atoms';
import {
	deleteAvatar,
	getUserAvatars,
	uploadAvatar,
} from './../../common/services/avatarsRequests';

const useStyles = makeStyles((theme) => ({
	close: {
		margin: 0,
		padding: 0,
	},
	avatar: {
		cursor: 'pointer',

		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	selectedAvatar: {
		border: '1px solid red',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: 4,
		top: 8,
		border: `0px solid ${theme.palette.background.paper}`,
		padding: '0',
	},
}))(Badge);

const UserInfoSelector = ({ open, setOpen }) => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [name, setName] = useRecoilState(userNameState);
	const [selectedAvatar, setSelectedAvatar] = useRecoilState(userAvatarState);
	const [avatars, setAvatars] = useState([]);
	const [loading, setLoading] = useState(false);
	const [avatarToDelete, setAvatarToDelete] = useState();
	const [showDeleteAvatar, setShowDeleteAvatar] = useState(false);
	let nameInput = useRef();

	const handleSave = () => {
		if (nameInput.current.value !== '') {
			setName(nameInput.current.value);
			setOpen(false);
		}
	};

	const fetchAvatars = async () => {
		setLoading(true);
		const response = await getUserAvatars();
		setAvatars(response.avatars);
		if (response?.avatars?.length === 1) {
			setSelectedAvatar(response?.avatars[0]);
		}
		setLoading(false);
	};

	const deleteAvatarById = async () => {
		setLoading(true);
		setShowDeleteAvatar(false);
		await deleteAvatar(avatarToDelete);
		await fetchAvatars();
		setLoading(false);
	};

	const onDrop = useCallback(async (file) => {
		console.log('received binary file', file);
		setLoading(true);
		const newFile = await uploadAvatar(file.binary, file);
		await fetchAvatars();
		setLoading(false);
	}, []);

	useEffect(() => {
		if (open) {
			fetchAvatars();
		}
	}, [open]);

	return (
		<Box data-testid="UserInfoSelectorContainer" id="UserInfoSelectorContainer">
			<Dialog open={open} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{t('UserInfoSelector.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('UserInfoSelector.content')}</DialogContentText>
					<Box display="flex" flexDirection="column">
						<TextField
							data-testid="userNameField"
							defaultValue={name}
							autoFocus
							margin="dense"
							id="name"
							label={t('UserInfoSelector.fields.name')}
							type="text"
							fullWidth
							inputRef={nameInput}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSave();
								}
							}}
						/>
						<Box display="flex">
							{avatars.map((avatar) => {
								return (
									<IconButton aria-label="cart" size="large">
										<StyledBadge
											badgeContent={
												<Cancel
													className={classes.close}
													onClick={() => {
														setAvatarToDelete(avatar.ID);
														setShowDeleteAvatar(true);
													}}></Cancel>
											}
											color="secondary">
											<Avatar
												className={`${classes.avatar} ${
													avatar?.ID === selectedAvatar?.ID
														? classes.selectedAvatar
														: ''
												}`}
												onClick={() => {
													if (avatar != selectedAvatar) {
														setSelectedAvatar(avatar);
													} else {
														setSelectedAvatar();
													}
												}}
												src={avatar.url}
											/>
										</StyledBadge>
									</IconButton>
								);
							})}
						</Box>
						<DropZone onFileReady={onDrop}></DropZone>
						<Backdrop className={classes.backdrop} open={loading}>
							<CircularProgress color="inherit" />
						</Backdrop>
						<ConfirmDialog
							open={showDeleteAvatar}
							setOpen={setShowDeleteAvatar}
							title={t('UserInfoSelector.deleteAvatarDialog.title')}
							text={t('UserInfoSelector.deleteAvatarDialog.text')}
							successButtonText={t(
								'UserInfoSelector.deleteAvatarDialog.buttons.success'
							)}
							cancelButtonText={t(
								'UserInfoSelector.deleteAvatarDialog.buttons.cancel'
							)}
							onSuccess={deleteAvatarById}></ConfirmDialog>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleSave}
						color="primary"
						data-testid="rollUserInfoSelectorButton">
						{t('UserInfoSelector.buttons.save')}
					</Button>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						color="primary"
						data-testid="cancelUserInfoSelectorButton">
						{t('UserInfoSelector.buttons.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default UserInfoSelector;
