import PubSub from 'pubsub-js';

import { CLIENT_EVENTS, USER_STREAM_STATUS } from './../../common/constants';

class UserStream {
	mediaStreamConstraints = {
		iceServers: [
			...this.getStunServers(),
			{
				urls: 'turn:openrelay.metered.ca:80',
				username: 'openrelayproject',
				credential: 'openrelayproject',
			},
			{
				urls: 'turn:openrelay.metered.ca:443',
				username: 'openrelayproject',
				credential: 'openrelayproject',
			},
			{
				urls: 'turn:openrelay.metered.ca:443?transport=tcp',
				username: 'openrelayproject',
				credential: 'openrelayproject',
			},
		],
		video: true,
		audio: true,
	};
	offerOptions = {
		offerToReceiveVideo: 1,
	};
	logs = [];
	props = undefined;
	peersSdpShared = false;
	candidatesBackup = [];

	constructor(props) {
		this.props = props;
		this.id = this.props.remoteId;
		this.createStream();
		this.setDataChannel();
		if (this.props.status === 'active') {
			this.pong();
		} else {
			// this.establishConnection();
			this.ping();
		}

		PubSub.subscribe(CLIENT_EVENTS.SIGNALING, this.gotMessageFromSignaling.bind(this));
		PubSub.subscribe(CLIENT_EVENTS.RESYNC_USER, this.resyncUser.bind(this));
		PubSub.subscribe(CLIENT_EVENTS.DELETE_USER, this.deleteUser.bind(this));
	}

	getStunServers() {
		const stunServers = [
			{
				urls: 'stun:stun1.l.google.com:19302',
			},
			{
				urls: 'stun:stun2.l.google.com:19305',
			},
			{
				urls: 'stun:stun1.l.google.com:19305',
			},
			{
				urls: 'stun:stun4.l.google.com:19302',
			},
			{
				urls: 'stun:stun.l.google.com:19305',
			},
			{
				urls: 'stun:stun3.l.google.com:19302',
			},
			{
				urls: 'stun:openrelay.metered.ca:80',
			},
		];
		for (let i = stunServers.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[stunServers[i], stunServers[j]] = [stunServers[j], stunServers[i]];
		}
		return stunServers;
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	createStream() {
		if (this.stream) {
			this.stream.close();
		}
		this.stream = new RTCPeerConnection(this.mediaStreamConstraints);
		this.status = USER_STREAM_STATUS.CREATED;

		this.stream.addStream(this.props.localMedia);

		// for (const track of this.props.localMedia.getTracks()) {
		// 	this.stream.addTrack(track, this.props.localMedia);
		// }

		// ICE CANDIDATE
		this.stream.onicecandidate = (event) => {
			if (event.candidate) {
				this.sendCandidate(event);
			}
		};

		// EVENT ADD STREAM
		this.stream.onaddstream = (event) => {
			this.mediaStream = event.stream;
			this.status = USER_STREAM_STATUS.ESTABLISHED;
			if (!this.isStreamDisconnected()) {
				this.props.addStream(this.props.remoteId, this.mediaStream);
			}
		};

		this.stream.onconnectionstatechange = (event) => {
			this.addLog('onconnectionstatechange');
			if (this.isStreamDisconnected()) {
				//this.props.removeStream(this.id);
				this.props.client.send({
					action: 'room',
				});
				PubSub.subscribe(CLIENT_EVENTS.ROOM_UPDATE, this.checkUserStatus.bind(this));
			}
		};

		this.stream.onicegatheringstatechange = (event) => {
			this.addLog('onicegatheringstatechange');
		};

		this.stream.onsignalingstatechange = (event) => {
			this.addLog('onsignalingstatechange');
		};

		this.stream.oniceconnectionstatechange = (event) => {
			this.addLog('oniceconnectionstatechange');
			if (
				this.stream.connectionState === 'failed' &&
				this.stream.iceConnectionState === 'disconnected'
			) {
				//userDisconnected(userStream.id);
			}
		};

		this.stream.onnegotiationneeded = (event) => {
			this.addLog('onnegotiationneeded');
			if (this.status !== USER_STREAM_STATUS.CREATED) {
				this.establishConnection();
			}
			// if (userStream.status !== USER_STREAM_STATUS.CREATED) {
			// 	establishConnection(userStream);
			// }
		};
	}

	onDatachannelOpen() {
		this.addLog('dataChannelOnOpen');
		if (!this.isStreamDisconnected()) {
			this.props.addDataChannel(this.id, this.channel);
		}
	}

	setDataChannel() {
		// DATACHANNEL
		if (this.props.status !== 'active') {
			this.channel = this.stream.createDataChannel('chat');
			this.initDataChannel();
		} else {
			this.stream.ondatachannel = (event) => this.initDataChannel(event);
		}
	}

	initDataChannel(event) {
		if (event) {
			this.channel = event?.channel;
		}

		this.channel.onopen = () => this.onDatachannelOpen();
		// this.channel.onmessage = (message) => {
		// 	console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! on message', message.data);
		// 	this.props.addNewRemoteMessage(this.id, message.data);
		// };
		this.channel.onerror = (event) => {
			//TODO: REmove user when failing
			console.log('Data channel on error', event);
			this.props.removeDataChannel(this.id);
			this.addLog('dataChannelOnError');
		};
		this.channel.onclose = (event) => {
			console.log('Data channel on close', event);
			this.addLog('dataChannelOnClose');
			//userDisconnected(userStream.id);
		};
		this.channel.onclosing = (event) => {
			console.log('Data channel on closing', event);
			this.addLog('dataChannelOnClosing');
		};
	}

	async addLog(event, extra = {}, repeat = true) {
		const now = new Date();
		const log = {
			connectionState: this.stream.connectionState || 'noinfo',
			event: event || 'noinfo',
			iceConnectionState: this.stream.iceConnectionState || 'noinfo',
			iceGatheringState: this.stream.iceGatheringState || 'noinfo',
			signalingState: this.stream.signalingState || 'noinfo',
			time: `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
			dataChannel: this.channel?.readyState,
			...extra,
		};
		if (!Object.isExtensible(this.logs)) {
			this.logs = [...this.logs];
		}
		try {
			this.logs.push(log);
		} catch (e) {
			console.error(`Error ${e} trying to add log to user ${this.id}`);
		}
		if (repeat) {
			await this.sleep(5000);
			this.addLog(`repeat-${event}`, extra, false);
		}
	}

	isStreamDisconnected(event) {
		return (
			this.stream.connectionState === 'failed' &&
			this.stream.iceConnectionState === 'disconnected'
		);
	}

	establishConnection() {
		this.status = USER_STREAM_STATUS.SYNC;

		// CREATE OFFER
		this.stream
			.createOffer(this.offerOptions)
			.then((description) => {
				this.stream
					.setLocalDescription(description)
					.then(() => {
						this.props.client.send({
							action: 'signaling',
							description: this.stream.localDescription,
							type: 'sdp',
							toId: this.props.remoteId,
							fromId: this.props.id,
						});
					})
					.catch((e) => {
						this.addLog('setLocalDescription', e);
						this.handleError(e);
					});
			})
			.catch((e) => {
				this.addLog('createOffer', e);
				this.handleError(e);
			});
	}

	deleteUser(message, data) {
		if (data == this.id) {
			console.log('delete data is equal to this id', this.id);
			//this.stream.close();
		}
	}

	resyncUser(message, data) {
		if (data == this.id) {
			console.log('resync data is equal to this id', this.id);
			//this.resync();
		}
	}

	gotMessageFromSignaling(message, data) {
		if (data.fromId !== this.id) {
			return;
		}
		if (data.event === 'room-update') {
			this.checkUserStatus(data);
		}
		this.status = USER_STREAM_STATUS.SYNC;
		switch (data.type) {
			case 'candidate':
				if (data.candidate) {
					//this.gotIceCandidate(data.candidate);
					try {
						if (this.peersSdpShared) {
							this.stream
								.addIceCandidate(new RTCIceCandidate(data.candidate))
								.catch((e) => {
									console.log('error on gotIceCandidate', e);
									console.log('error value candidate', data.candidate);
									this.addLog('addIceCandidate', e);
									//this.handleError
								});
						} else {
							console.log('save candidate to backup', this.id, data.candidate);
							this.candidatesBackup.push(data.candidate);
						}
					} catch (e) {
						console.log('!!!!!!!!!!!Error trying to add iceCandidate', e);
					}
				}
				break;
			case 'pong':
				if (this.props.status === 'pasive') {
					this.establishConnection();
				}
				//this.establishConnection();
				break;
			case 'resync':
				this.createStream();
				this.setDataChannel();
				this.pong();
				break;
			case 'user_not_found':
				this.userNotFound();
				break;
			case 'sdp':
				if (data.description) {
					this.stream
						.setRemoteDescription(new RTCSessionDescription(data.description))
						.then(() => {
							if (data.description.type === 'offer') {
								this.stream
									.createAnswer()
									.then((description) => {
										this.stream.setLocalDescription(description).then(() => {
											this.props.client.send({
												action: 'signaling',
												description: this.stream.localDescription,
												type: 'sdp',
												toId: this.props.remoteId,
												fromId: this.props.id,
											});
										});
									})
									.catch((e) => {
										this.addLog('createAnswer', e);
										this.handleError(e);
									});
							}
							this.peersSdpShared = true;
							this.reviewPeerBackedUpCandidates();
						})
						.catch((e) => {
							this.addLog('setRemoteDescription', e);
							this.handleError(e);
						});
				}
				break;
			default:
				break;
		}
	}
	reviewPeerBackedUpCandidates() {
		this.candidatesBackup.forEach((candidate) => {
			console.log('set backed up candidate for ', this.id, candidate);
			this.stream.addIceCandidate(new RTCIceCandidate(candidate));
		});
		this.candidatesBackup = [];
	}

	gotIceCandidate(candidate) {
		this.stream.addIceCandidate(new RTCIceCandidate(candidate)).catch((e) => {
			console.log('error on gotIceCandidate', e);
			console.log('error value candidate', candidate);
			this.addLog('addIceCandidate', e);
			//this.handleError
		});
	}
	handleError = (error) => {
		console.log('error handled', error);
	};

	ping() {
		this.props.client.send({
			action: 'signaling',
			type: 'ping',
			toId: this.props.remoteId,
			fromId: this.props.id,
		});
	}

	pong() {
		this.props.client.send({
			action: 'signaling',
			type: 'pong',
			toId: this.props.remoteId,
			fromId: this.props.id,
		});
	}

	resync() {
		this.props.client.send({
			action: 'signaling',
			type: 'resync',
			toId: this.props.remoteId,
			fromId: this.props.id,
		});
	}

	delete() {
		try {
			this.channel.send({ type: -9, id: this.props.id });
			this.stream.close();
		} catch (e) {}
	}

	checkUserStatus(event, data) {
		if (!data.clients.some((x) => x === this.id)) {
			this.userNotFound();
		}
	}

	userNotFound() {
		this.addLog('USER_NOT_FOUND');
		this.props.removeStream(this.id);
	}

	sendCandidate(event) {
		this.props.client.send({
			action: 'signaling',
			type: 'candidate',
			candidate: event.candidate,
			toId: this.props.remoteId,
			fromId: this.props.id,
		});
	}

	updateLocalMedia(newLocalMedia) {
		this.stream.removeStream(newLocalMedia);
		this.stream.addStream(newLocalMedia);
	}
}

export default UserStream;
