import React, { useState } from 'react';
import { Box, Breadcrumbs, Link, Paper, Tabs, Tab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import LibraryContent from './LibraryContent';
import {
	currentLibraryBreadCrumbState,
	currentLibraryTypeState,
} from '../../../common/recoil/Library/libraryAtom';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 'calc(100% - 112px)',
		maxWidth: '500px',
	},
	breadcrumbs: {
		margin: '1px 5px',
	},
}));

const Libraries = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const [currentTab, setCurrentTab] = useState(0);
	const [currentLibraryBreadCrumb, setCurrentLibraryBreadCrumb] = useRecoilState(
		currentLibraryBreadCrumbState
	);
	const [currentLibraryType, setCurrentLibraryType] = useRecoilState(currentLibraryTypeState);

	const handleTabChange = (event, newValue) => {
		setCurrentLibraryType(newValue);
	};

	function handleBreadCrumbClick(event, name, isLibrary, position) {
		event.preventDefault();
		console.info(`You clicked a breadcrumb ${name} and is ${!isLibrary ? 'not' : ''} library`);
		const tempBreadCrumb = [...currentLibraryBreadCrumb.slice(0, position + 1)];
		setCurrentLibraryBreadCrumb(tempBreadCrumb);
	}

	const dynamicBreadcrumbs = () => (
		<Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
			{currentLibraryBreadCrumb.map((x, index) => {
				return index < currentLibraryBreadCrumb.length - 1 ? (
					<Link
						color="inherit"
						href="/"
						onClick={(event) => {
							handleBreadCrumbClick(event, x, index === 0, index);
						}}>
						{x}
					</Link>
				) : (
					<Typography color="textPrimary">{x}</Typography>
				);
			})}
		</Breadcrumbs>
	);

	return (
		<Box className={classes.root}>
			<Paper square>
				{currentLibraryBreadCrumb.length > 0 ? (
					dynamicBreadcrumbs()
				) : (
					<Tabs
						value={currentLibraryType}
						indicatorColor="primary"
						textColor="primary"
						onChange={handleTabChange}
						aria-label="library tabs">
						<Tab label={t('LibrariesManager.tabs.public')} />
						<Tab label={t('LibrariesManager.tabs.private')} />
						<Tab label={t('LibrariesManager.tabs.rules')} />
					</Tabs>
				)}
			</Paper>
			<LibraryContent />
		</Box>
	);
};

export default Libraries;
