import { useEffect } from 'react';

function useRoom(client) {
	useEffect(() => {
		if (client) {
			if (client.readyState === 1) {
				start();
			} else {
				client.onopen = () => {
					start();
				};
			}
		}
	}, []);

	const enterInRoom = () => {
		client.send(
			JSON.stringify({
				action: 'room',
				username: 'username',
				message: 'some weird message',
			})
		);
	};

	const ping = () => {
		client.send(
			JSON.stringify({
				action: 'ping',
			})
		);
	};

	return enterInRoom;

	function start() {
		enterInRoom();
		setInterval(() => {
			ping();
		}, 60 * 1000 * 7);
	}
}

export default useRoom;
