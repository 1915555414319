import React, { memo, useEffect, useRef, useState } from 'react';
import { Avatar, Box, IconButton } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material/';
import { useRecoilValue, useRecoilState } from 'recoil';
import { css } from '@emotion/react';

import './VideoWebrtc.scss';

import { FAKE_STREAM } from './../../common/constants';
import { microOnState, userNameState, videoOnState } from './../../common/recoil/UserInfo/atoms';
import { drawDice } from './../Dices/helper/diceInit';
import { DiceIcons, DiceResult, DiceResultAlert, StreamMenu } from './../../components';
import { IS_LOCALHOST } from './../../common/constants';

const VideoWebrtc = ({
	avatarUrl,
	diceResults,
	mediaStream,
	userId,
	isShare,
	isLocalUser,
	remoteUserName,
	useCameraClass = 'camera',
}) => {
	const videoRef = useRef();

	const [microOn, setMicroOn] = useRecoilState(microOnState);
	const [videoOn, setVideoOn] = useRecoilState(videoOnState);
	const [isFake, setIsFake] = useState(false);
	const userName = useRecoilValue(userNameState);

	useEffect(() => {
		if (mediaStream && mediaStream.type !== FAKE_STREAM) {
			videoRef.current.srcObject = mediaStream;
			mediaStream.oninactive = () => {
				console.log(`end streaming ${userId}`);
			};
			if (IS_LOCALHOST && isLocalUser) {
				stopCamera();
				stopAudio();
			}
		} else if (mediaStream && mediaStream.type === FAKE_STREAM) {
			setIsFake(true);
		}
	}, [mediaStream]);

	useEffect(() => {}, [diceResults]);

	const handleCanPlay = () => {
		console.log('play');
		videoRef.current.play();
	};

	const stopCamera = () => {
		mediaStream.getVideoTracks()[0].enabled = !mediaStream.getVideoTracks()[0].enabled;
		setVideoOn(!videoOn);
	};

	const stopAudio = () => {
		mediaStream.getAudioTracks()[0].enabled = !mediaStream.getAudioTracks()[0].enabled;
		setMicroOn(!microOn);
	};

	return (
		<Box className={useCameraClass}>
			{!isLocalUser && <StreamMenu userId={userId} />}

			<Box className="video">
				{!isFake && (
					<video
						className={`video-webrtc ${isShare ? 'is-share' : ''}`}
						ref={videoRef}
						onCanPlay={handleCanPlay}
						autoPlay
						playsInline
						muted={isLocalUser === true}
					/>
				)}

				{isFake && <Box className="fake-video"> Fake stream </Box>}
				<h1>{isLocalUser ? userName : remoteUserName}</h1>
				{avatarUrl && <Avatar className="avatar" src={avatarUrl} />}

				{/* <h1>{userId}</h1> */}
			</Box>
			{/* <Box className="userName">
				<h1>{isLocalUser ? userName : remoteUserName}</h1>
			</Box> */}
			<Box className="diceContainer">
				{diceResults?.map((result, index) => {
					if (result) {
						if (Array.isArray(result)) {
							return result.map((x, subIndex) => {
								return (
									<Box className="newDice" key={`dice-${index}-${subIndex}`}>
										<DiceIcons dice={x.notation} size="medium" />
										<span>
											<DiceResult
												notation={x.notation}
												result={x.value}
												type={x.type}
											/>
										</span>
									</Box>
								);
							});
						} else if (result instanceof Object) {
							return (
								<Box className="newDice" key={`dice-${index}`}>
									<DiceIcons dice={result.notation} size="medium" />
									<span>
										<DiceResult
											notation={result.notation}
											result={result.value}
											type={result.type}
										/>
									</span>
								</Box>
							);
						} else {
							return (
								<Box
									css={css`
										background: url('/images/d10.png') no-repeat center
											transparent;
									`}
									className="dice">
									{' '}
									{result}{' '}
								</Box>
							);
						}
					}
				})}
			</Box>
			<Box className="cameraButtons">
				<IconButton
					color="primary"
					aria-label="upload picture"
					component="span"
					onClick={() => {
						stopAudio();
					}}
					size="large">
					{isLocalUser && (microOn ? <Mic></Mic> : <MicOff></MicOff>)}
				</IconButton>
				<IconButton
					color="primary"
					aria-label="upload picture"
					component="span"
					onClick={() => {
						stopCamera();
					}}
					size="large">
					{isLocalUser && (videoOn ? <Videocam></Videocam> : <VideocamOff></VideocamOff>)}
				</IconButton>
			</Box>
			<DiceResultAlert
				results={diceResults}
				isLocalUser={isLocalUser}
				userName={isLocalUser ? userName : remoteUserName}></DiceResultAlert>
		</Box>
	);
};

export default VideoWebrtc;
