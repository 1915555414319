import React, { useEffect, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	TextField,
	Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import {
	showCreateLibraryDialogState,
	currentLibraryTypeState,
	publicLibrariesState,
	privateLibrariesState,
	rulesLibraryState,
} from './../../../common/recoil/Library/libraryAtom';
import { LIBRARY_TYPE } from './../../../common/constants';

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '200px',
	},
}));

const CreateLibraryDialog = () => {
	const classes = useStyles();
	const [showCreateLibraryDialog, setShowCreateLibraryDialogState] = useRecoilState(
		showCreateLibraryDialogState
	);
	const [publicLibraries, setPublicLibraries] = useRecoilState(publicLibrariesState);
	const [privateLibraries, setPrivateLibraries] = useRecoilState(privateLibrariesState);
	const [rulesLibrary, setRulesLibrary] = useRecoilState(rulesLibraryState);

	const currentLibraryType = useRecoilValue(currentLibraryTypeState);
	const { t, i18n } = useTranslation();
	let nameInput = useRef();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [isPrivate, setIsPrivate] = useState(currentLibraryType === LIBRARY_TYPE.PRIVATE);
	const [isRule, setIsRule] = useState(currentLibraryType === LIBRARY_TYPE.RULES);

	useEffect(() => {
		setIsPrivate(currentLibraryType == LIBRARY_TYPE.PRIVATE);
		setIsRule(currentLibraryType == LIBRARY_TYPE.RULES);
	}, [currentLibraryType]);

	const handleSave = async () => {
		if (nameInput.current.value !== '') {
			if (!checkLibraryExists()) {
				const currentUserInfo = await Auth.currentUserInfo();

				const library = {
					name: nameInput.current.value,
					userId: isPrivate ? currentUserInfo.username : isRule ? 'rules' : 'public',
					dirty: true,
				};

				if (isPrivate) {
					const tempPrivateLibraries = [...privateLibraries];
					setPrivateLibraries([...tempPrivateLibraries, library]);
				} else if (isRule) {
					const tempRulesLibrary = [...rulesLibrary];
					setRulesLibrary([...tempRulesLibrary, library]);
				} else {
					const tempPublicLibraries = [...publicLibraries];
					setPublicLibraries([...tempPublicLibraries, library]);
				}
				setShowCreateLibraryDialogState(false);
			} else {
				showError(t('LibrariesManager.createLibraryDialog.exists'));
			}
		}
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const showError = (message) => {
		enqueueSnackbar(message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			action: (key) => (
				<Button onClick={onClickDismiss(key)}>
					<Close color={'white'}></Close>
				</Button>
			),
			preventDuplicate: true,
		});
	};

	const checkLibraryExists = () => {
		if (isPrivate) {
			return privateLibraries.some((x) => x.name === nameInput.current.value);
		} else if (isRule) {
			return rulesLibrary.some((x) => x.name === nameInput.current.value);
		} else {
			return publicLibraries.some((x) => x.name === nameInput.current.value);
		}
	};

	const handleSwtichPrivate = () => {
		setIsPrivate(!isPrivate);
	};

	return (
		<Dialog open={showCreateLibraryDialog} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">
				{t('LibrariesManager.createLibraryDialog.title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('LibrariesManager.createLibraryDialog.content')}
				</DialogContentText>
				<FormGroup row>
					<TextField
						data-testid="userNameField"
						autoFocus
						margin="dense"
						id="name"
						label={t('LibrariesManager.createLibraryDialog.placeholder')}
						type="text"
						fullWidth
						inputRef={nameInput}
						onKeyPress={(event) => {
							if (event.key === 'Enter') {
								handleSave();
							}
						}}
					/>
					{!isRule && (
						<FormControlLabel
							control={
								<Switch
									checked={isPrivate}
									onChange={handleSwtichPrivate}
									name="private"
									inputProps={{ 'aria-label': 'Switch private' }}
								/>
							}
							label="Privado"></FormControlLabel>
					)}
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setShowCreateLibraryDialogState(false);
					}}
					color="primary"
					data-testid="cancel">
					{t('diceSelector.buttons.cancel')}
				</Button>
				<Button onClick={handleSave} color="primary" data-testid="saveUserInfoButton">
					{t('userInfo.buttons.save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateLibraryDialog;
