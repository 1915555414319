import { Auth } from 'aws-amplify';
import Axios from 'axios';

import { AVATAR_MANAGER } from '../apis';

const generateConfig = (jwtToken, apiToken) => {
	return {
		headers: {
			Accept: '*/*',
			Authorization: jwtToken,
			'content-type': '',
			'X-API-KEY': apiToken,
		},
		timeout: 10000,
	};
};

const avatarApis = AVATAR_MANAGER[process.env.REACT_APP_API_ENV];

export const uploadAvatar = async (image, file) => {
	const currentSession = await Auth.currentSession();
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	let parts = image.split(';');
	let mime = parts[0].split(':').slice(1, 2)[0];
	let name = parts[1].split('=').slice(1, 100).join('');
	let data = parts[1];
	const imageToSend = {
		userId,
		image: data,
		mime,
		name: file?.name?.split('.')[0],
	};

	const config = generateConfig(currentSession.getIdToken().getJwtToken(), avatarApis.token);
	try {
		const response = await Axios.post(avatarApis.urls.uploadAvatar, imageToSend, config);
		if (response.data.statusCode === 200) {
			return {
				url: response.data.body.avatar,
			};
		} else {
			return null;
		}
	} catch (e) {
		console.log('error uploading avatar', e);
		return null;
	}
};

export const getUserAvatars = async () => {
	const currentSession = await Auth.currentSession();
	const config = generateConfig(currentSession.getIdToken().getJwtToken(), avatarApis.token);
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;

	try {
		const response = await Axios.get(avatarApis.urls.getUserAvatars(userId), config);
		return JSON.parse(response.data.body).data;
	} catch (e) {
		console.log('error retrieving avatars', e);
		return {
			avatars: [],
		};
	}
};

export const deleteAvatar = async (id) => {
	const currentSession = await Auth.currentSession();
	const config = generateConfig(currentSession.getIdToken().getJwtToken(), avatarApis.token);
	await Axios.delete(avatarApis.urls.deleteAvatar(id), config);
};
