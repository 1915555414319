import { Auth } from 'aws-amplify';
import Axios from 'axios';

import { LIBRARY_MANAGER } from '../apis';
import { IMAGE_MIMES, AUDIO_MIMES } from '../constants';

const libraryManagerApi = LIBRARY_MANAGER[process.env.REACT_APP_API_ENV];
const generateConfig = async () => {
	const currentSession = await Auth.currentSession();

	return {
		headers: {
			Accept: '*/*',
			Authorization: currentSession.getIdToken().getJwtToken(),
			'content-type': '',
			'X-API-KEY': libraryManagerApi.token,
		},
	};
};

export const getLibrary = async (name, isPublic, isRule) => {
	const currentUserInfo = await Auth.currentUserInfo();
	const config = await generateConfig();
	const userId = !isPublic ? currentUserInfo.username : isRule ? 'rules' : undefined;

	const response = await Axios.get(libraryManagerApi.urls.getLibrary(name, userId), config);

	return JSON.parse(response.data.body).data.library;
};

export const createLibrary = async (id, isPrivate, isRule, libraryFile, name) => {
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = await generateConfig();
	const libraryToSave = { ...libraryFile, dirty: false };
	const libraryEntry = {
		id: id,
		libraryFile: JSON.stringify(libraryToSave),
		name,
		userId: 'public',
	};

	if (isPrivate) {
		libraryEntry.userId = userId;
	} else if (isRule) {
		libraryEntry.userId = 'rules';
	}

	const response = await Axios.post(libraryManagerApi.urls.createLibrary, libraryEntry, config);
	return JSON.parse(response.data.body).data.library;
};

export const getUserLibraries = async () => {
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = await generateConfig();
	const response = await Axios.get(libraryManagerApi.urls.getLibraries(userId), config);
	return JSON.parse(response.data.body).data.allLibraries;
};

export const uploadFile = async (file, hash) => {
	const signedInfo = await getSignedUrl(file, hash);
	if (signedInfo.status === 'exists') {
		return await getSignedFile(signedInfo.key, file);
	} else {
		try {
			await uploadFileFromSignedUrl(signedInfo, file);
			return await getSignedFile(signedInfo.fields['key'], file);
		} catch (e) {
			await deleteFile(hash);
		}
	}
};

export const deleteFolder = async (id, path) => {
	const config = await generateConfig();
	const response = await Axios.delete(
		libraryManagerApi.urls.deleteLibraryFolder(id, path),
		config
	);
	return response;
};

export const deleteFile = async (id) => {
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = await generateConfig();
	const response = await Axios.delete(
		libraryManagerApi.urls.deleteLibraryFile(id, userId),
		config
	);
	return response;
};

export const deleteLibrary = async (id) => {
	const config = await generateConfig();
	const response = await Axios.delete(libraryManagerApi.urls.deleteLibrary(id), config);
	return response;
};

export const getSignedUrl = async (file, hash) => {
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = await generateConfig();
	const extension = file.name.split('.')[1];

	const response = await Axios.get(
		libraryManagerApi.urls.getFilePresignedPost(userId, extension, hash, file.size),
		config
	);
	return JSON.parse(response.data.body).data;
};

export const uploadFileFromSignedUrl = async (signedInfo, file) => {
	let form = new FormData();
	Object.keys(signedInfo.fields).forEach((key) => form.append(key, signedInfo.fields[key]));
	form.append('file', file);

	await Axios.post(signedInfo.url, form, {
		timeout: 10000,
	});
};

export const getSignedFile = async (key, file) => {
	const config = await generateConfig();
	const signedFile = await Axios.get(libraryManagerApi.urls.getSignedFile(key), config);
	if (signedFile.data.statusCode === 200) {
		try {
			const imageUrl = JSON.parse(signedFile.data.body).data.imageURL;
			return {
				url: imageUrl,
				isImage: IMAGE_MIMES.includes(file.type),
				isAudio: AUDIO_MIMES.includes(file.type),
				mime: file.type,
				key: key,
				id: key.split('/')[1].split('.')[0].replaceAll('-', ''),
			};
		} catch (e) {
			console.error(e);
			return null;
		}
	} else {
		return null;
	}
};
