import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box } from '@mui/material';

import { CHAT_EVENTS } from './../../common/constants';
import { remoteChatState } from './../../common/recoil/chatMessages/atoms';
import {
	alreadySharedImageIdsState,
	localShareActiveState,
	shareStreamState,
	shareCanvasImgState,
	shareDrawIntervalState,
	shareTempImageReadyState,
} from './../../common/recoil/Share/atom';
import { isShareActiveSelector } from './../../common/recoil/Share/selectors';
import { userIdState } from './../../common/recoil/UserInfo/atoms';
import { useChat, useOffsets, useShare, useVideoRatio } from './../../common/hooks';

import './SharingArea.scss';

const SharingArea = () => {
	const videoRef = useRef();
	const canvasRef = useRef();
	const [share, setShare] = useRecoilState(shareStreamState);
	const [canvasImage, setCanvasImage] = useRecoilState(shareCanvasImgState);
	const [drawInterval, setDrawInterval] = useRecoilState(shareDrawIntervalState);
	const [shareTempImageReady, setShareTempImageReady] = useRecoilState(shareTempImageReadyState);
	const [container, setContainer] = useState({ width: 0, height: 0 });
	const [aspectRatio, calculateRatio] = useVideoRatio(1.586);
	const [remoteChat, setRemoteChat] = useRecoilState(remoteChatState);
	const { messages, sendMessage } = useChat();
	const offsets = useOffsets(
		videoRef.current && videoRef.current.videoWidth,
		videoRef.current && videoRef.current.videoHeight,
		container.width,
		container.height
	);
	const isShareActive = useRecoilValue(isShareActiveSelector);
	const alreadySharedImageIds = useRecoilValue(alreadySharedImageIdsState);
	const userId = useRecoilValue(userIdState);
	const [localShareActive, setLocalShareActive] = useRecoilState(localShareActiveState);
	let drawImageTimeout = 4000;

	useShare();
	useEffect(() => {
		if (share) {
			videoRef.current.srcObject = share;
			share.oninactive = async () => {
				stopSharing();
			};
		}
	}, [share]);

	useEffect(() => {
		if (remoteChat && drawInterval > 0) {
			resetDrawImageInterval();
		}
	}, [remoteChat]);

	useEffect(() => {
		if (shareTempImageReady && drawInterval > 0) {
			resetDrawImageInterval();
		}
	}, [shareTempImageReady]);

	useEffect(() => {
		if (canvasImage && !localShareActive) {
			const nextId = getNextIdToSendImage(alreadySharedImageIds);
			if (nextId) {
				sendImage(canvasImage, nextId, alreadySharedImageIds);
			}
		}
	}, [canvasImage]);

	const getNextIdToSendImage = (alreadySharedImageIds = []) => {
		const nextId = remoteChat.filter((x) => {
			return !alreadySharedImageIds.includes(x.id) && x.channel.readyState === 'open';
		})[0]?.id;
		return nextId;
	};

	const resetDrawImageInterval = () => {
		//setDrawInterval(clearInterval(drawInterval));
		//setDrawInterval(setInterval(drawImage, drawImageIntervalTime));
	};

	const handleCanPlay = () => {
		calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
		videoRef.current.play();
	};

	const handleOnPlay = () => {
		setContainer({
			width: videoRef.current.videoWidth,
			height: videoRef.current.videoHeight,
		});
		//setDrawInterval(setInterval(drawImage, drawImageIntervalTime));
		drawImage();
		document.videoRef = videoRef;
		document.canvasRef = canvasRef;
	};

	const onCapture = (image) => {};

	function drawImage() {
		if (!shareTempImageReady) {
			//resetDrawImageInterval();
			return;
		}
		if (videoRef.current && share.active) {
			const context = canvasRef.current.getContext('2d');
			context.drawImage(
				videoRef.current, // source
				0, // sx
				0, // sy
				videoRef.current.videoWidth, // sWidth
				videoRef.current.videoHeight // sHeight
			);
			canvasRef.current.toBlob((blob) => onCapture(blob), 'image/jpeg', 1);
			setCanvasImage(canvasRef.current.toDataURL('image/png'));
			const nextId = getNextIdToSendImage();
			sendImage(canvasRef.current.toDataURL('image/jpeg'), nextId);
			setTimeout(drawImage, drawImageTimeout);
		} else {
			stopSharing();
		}
	}

	const sendImage = (data, toId, sharedUsers = []) => {
		var delay = 10;
		var charSlice = 10000;
		//var data = canvas.toDataURL("image/jpeg");
		var dataSent = 0;
		var intervalID = 0;

		intervalID = setInterval(function () {
			// if (!share.active) {
			// 	clearInterval(intervalID);
			// 	stopSharing();
			// 	return;
			// }
			var slideEndIndex = dataSent + charSlice;
			if (slideEndIndex > data.length) {
				slideEndIndex = data.length;
			}
			const tempSharedUsers = [...sharedUsers];
			tempSharedUsers.push(userId);
			let shareImgMessage = {
				type: CHAT_EVENTS.SHARING,
				img: data.slice(dataSent, slideEndIndex),
				toId: toId,
				sharedUsers: tempSharedUsers,
			};

			setShareTempImageReady(false);
			sendMessage(shareImgMessage, shareImgMessage.toId);
			dataSent = slideEndIndex;
			if (dataSent + 1 >= data.length) {
				try {
					shareImgMessage.complete = true;
				} catch (e) {
					shareImgMessage = {
						...shareImgMessage,
						complete: true,
					};
					console.log('error adding complete');
				}

				sendMessage(shareImgMessage, shareImgMessage.toId);
				clearInterval(intervalID);
				setShareTempImageReady(true);
			}
		}, delay);
	};

	const sendStopSharing = () => {
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARING,
			finished: true,
		};
		sendMessage(shareImgMessage, shareImgMessage.toId);
	};

	const stopSharing = () => {
		videoRef.current.srcObject = null;
		setCanvasImage(null);
		//		setDrawInterval(clearInterval(drawInterval));
		setShare(null);
		setLocalShareActive(false);
		sendStopSharing();
	};

	return (
		<Box className="share">
			<Box className="video">
				<video
					id="sharingVideo"
					ref={videoRef}
					onCanPlay={handleCanPlay}
					onPlay={handleOnPlay}
					autoPlay
					playsInline
					muted
				/>
				{canvasImage && (
					<img data-testid="canvas-img" className="canvasImage" src={canvasImage}></img>
				)}
				<canvas
					ref={canvasRef}
					id="canvasContainer"
					width={container.width}
					height={container.height}></canvas>
			</Box>
		</Box>
	);
};

export default SharingArea;
