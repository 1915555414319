import { Auth } from 'aws-amplify';
import Axios from 'axios';

import { FILE_MANAGER } from '../apis';
import { IMAGE_MIMES, AUDIO_MIMES } from '../constants';

const generateConfig = (jwtToken, apiToken) => {
	return {
		headers: {
			Accept: '*/*',
			Authorization: jwtToken,
			'content-type': '',
			'X-API-KEY': apiToken,
		},
	};
};

export const uploadImage = async (image, onSuccess, onError) => {
	const fileApi = FILE_MANAGER;
	const currentSession = await Auth.currentSession();
	let parts = image.split(';');
	let mime = parts[0].split(':').slice(1, 2)[0];
	let name = parts[1].split('=').slice(1, 100).join('');
	let data = parts[1];
	const imageToSend = {
		mime: mime,
		name: 'name.png',
		image: data,
	};

	const config = generateConfig(currentSession.getIdToken().getJwtToken(), fileApi.token);
	const response = await Axios.post(fileApi.urls.uploadImage, imageToSend, config);
	if (response.data.statusCode === 200) {
		return {
			url: response.data.body.imageURL,
			isImage: IMAGE_MIMES.includes(response.data.body.mime),
			isAudio: AUDIO_MIMES.includes(response.data.body.mime),
			mime: response.data.body.mime,
		};
	} else {
		return null;
	}
};

export const uploadFile = async (file) => {
	const signedInfo = await getSignedUrl(file);
	await uploadFileFromSignedUrl(signedInfo, file);
	return await getSignedFile(signedInfo.fields['key'], file);
};

export const uploadLibrary = async (library, isPrivate, key, isRule) => {
	const fileManagerApi = FILE_MANAGER;
	const currentSession = await Auth.currentSession();
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = currentUserInfo.username;
	const config = generateConfig(currentSession.getIdToken().getJwtToken(), fileManagerApi.token);

	const libraryObj = {
		library: JSON.stringify(library),
		key,
	};

	if (isPrivate) {
		libraryObj.userId = userId;
	}
	if (isRule) {
		libraryObj.userId = 'rules';
	}

	const response = await Axios.post(fileManagerApi.urls.createLibrary, libraryObj, config);
	return JSON.parse(response.data.body);
};

export const getSignedUrl = async (file) => {
	const fileManagerApi = FILE_MANAGER;
	const currentSession = await Auth.currentSession();
	const currentCredentials = await Auth.currentCredentials();
	const currentUserInfo = await Auth.currentUserInfo();

	const userId = currentUserInfo.username;
	const extension = file.name.split('.')[1];

	const config = generateConfig(currentSession.getIdToken().getJwtToken(), fileManagerApi.token);
	debugger;
	const response = await Axios.get(
		fileManagerApi.urls.getS3PresignedPost(userId, extension),
		config
	);
	return response.data.body;
};

export const uploadFileFromSignedUrl = async (signedInfo, file) => {
	let form = new FormData();
	Object.keys(signedInfo.fields).forEach((key) => form.append(key, signedInfo.fields[key]));
	form.append('file', file);

	await Axios.post(signedInfo.url, form);
};

export const getSignedFile = async (key, file) => {
	const currentSession = await Auth.currentSession();

	const fileManagerApi = FILE_MANAGER;
	const config = generateConfig(currentSession.getIdToken().getJwtToken(), fileManagerApi.token);
	const signedFile = await Axios.get(fileManagerApi.urls.getSignedFile(key), config);
	if (signedFile.data.statusCode === 200) {
		return {
			url: signedFile.data.body.imageURL,
			isImage: IMAGE_MIMES.includes(file.type),
			isAudio: AUDIO_MIMES.includes(file.type),
			mime: file.type,
			key: key,
			id: key.split('/')[1].split('.')[0].replaceAll('-', ''),
		};
	} else {
		return null;
	}
};

export const getLibraryFile = async (name, isPublic, isRule) => {
	const fileManagerApi = FILE_MANAGER;
	const currentSession = await Auth.currentSession();
	const config = generateConfig(currentSession.getIdToken().getJwtToken(), fileManagerApi.token);
	const currentUserInfo = await Auth.currentUserInfo();
	const userId = !isPublic ? currentUserInfo.username : isRule ? 'rules' : undefined;

	const response = await Axios.get(fileManagerApi.urls.getLibraryFile(name, userId), config);

	return JSON.parse(response.data.body).library;
};
