import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Dialog,
    IconButton,
    List,
    ListItem,
    Slide,
    Toolbar,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, ExpandMore } from '@mui/icons-material';

import { logsOpenState, logsState } from '../../common/recoil/UserInfo/atoms';
import { remoteNamesState } from '../../common/recoil/websocket/atoms';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	accordion: {
		flexDirection: 'column',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const LogViewer = ({ retrieveLogs }) => {
	const classes = useStyles();
	const [open, setOpen] = useRecoilState(logsOpenState);
	const [logs, setLogs] = useRecoilState(logsState);
	const remoteNames = useRecoilValue(remoteNamesState);
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (open) {
			const tempLogs = retrieveLogs();
			tempLogs.map((x) => {
				x.name = remoteNames.find((y) => y.id === x.id)?.name;
			});
			setLogs(tempLogs);
		}
	}, [open]);

	const getPrettyLog = (log) => {
		let prettyLog = '';
		for (let key in log) {
			if (log.hasOwnProperty(key)) {
				prettyLog += ` ${key} : ${log[key]} /`;
			}
		}
		return prettyLog;
	};

	return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        size="large">
						<Close />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Logs
					</Typography>
				</Toolbar>
			</AppBar>
			{logs.map((x) => (
				<Accordion className={classes.accordion} key={x.id}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id={x.name}>
						<Typography className={classes.heading}>{x.name || x.id}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<List>
							{x.logs.map((log, index) => (
								<ListItem key={`log-${index}`}>Log: {getPrettyLog(log)}</ListItem>
							))}
						</List>
					</AccordionDetails>
				</Accordion>
			))}
		</Dialog>
    );
};

export default LogViewer;
