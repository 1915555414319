import { selector } from 'recoil';

import { remoteMediaStreamsState } from './atoms';
import { userIdState } from './../UserInfo/atoms';

export const remoteMediaStreamsSelector = selector({
	key: 'remoteMediaStreamsState',
	get: ({ get }) => get(remoteMediaStreamsState),
	set: ({ get, set }, newMediaStream) => {
		const current = get(remoteMediaStreamsState);
		const myId = get(userIdState);
		if (!current.some((x) => x.id === newMediaStream.id) && myId != newMediaStream.id) {
			set(remoteMediaStreamsState, [...current, newMediaStream]);
		} else if (myId != newMediaStream.id) {
			const filtered = current.filter((x) => x.id !== newMediaStream.id);
			set(remoteMediaStreamsState, [...filtered, newMediaStream]);
		}
	},
});

export const updateRemoteMediaStreamsNameSelector = selector({
	key: 'updateRemoteMediaStreamsNameSelector',
	get: ({ get }) => get(remoteMediaStreamsState),
	set: ({ get, set }, name, id) => {
		const current = get(remoteMediaStreamsState);
		current = current.map((x) => {
			if (x.id === id) {
				x.name = name;
			}
			return x;
		});

		set(remoteMediaStreamsState, [...current]);
	},
});

export const removeMediaStreamsSelector = selector({
	key: 'RemoveMediaStreamsSelector',
	get: ({ get }) => get(remoteMediaStreamsState),
	set: ({ get, set }, id) => {
		const current = get(remoteMediaStreamsState);
		const newValue = current.filter((x) => {
			return x.id !== id;
		});

		set(remoteMediaStreamsState, newValue);
	},
});
