import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';

const showErrorTooltip = (enqueueSnackbar, closeSnackbar, message) => {
	enqueueSnackbar(message, {
		variant: 'error',
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		action: (key) => (
			<Button
				onClick={() => {
					onClickDismiss(key);
				}}>
				<Close color={'white'}></Close>
			</Button>
		),
		preventDuplicate: true,
	});

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};
};

export default showErrorTooltip;
