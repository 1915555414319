import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { GameCard } from '../../common/components';

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const Home = () => {
	const classes = useStyles();

	const defaultGameCard = {
		img: '//upload.wikimedia.org/wikipedia/commons/thumb/b/be/Role_playing_gamers.jpg/800px-Role_playing_gamers.jpg',
		name: 'Boires',
		description: 'Partida por defecto para boires',
		link: '/game',
	};

	return (
		<Grid container component="main" className={classes.root}>
			<GameCard game={defaultGameCard} />
		</Grid>
	);
};

export default Home;
