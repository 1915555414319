import React, { memo, useRef, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import * as THREE from 'three';
import * as CANNON from 'cannon';

import { diceInit, rollDice } from './helper/diceInit';
import { DICE_TYPES } from './../../common/constants';
import { diceRollState, isL5RDiceState } from './../../common/recoil/UserInfo/atoms';
import {
	customDiceSelectedState,
	isNextDiceExplosiveState,
	isNextDiceRepetitionState,
	repeatDicesState,
} from '../../common/recoil/GameSystem/gameSystemAtom';
import CustomDiceResults from './CustomDiceResults';

import './Dices.scss';

const Dices = ({ diceResults, setDiceResults }) => {
	const [result, setResult] = useState();
	const [roll, setRoll] = useRecoilState(diceRollState);
	const [isNextDiceExplosive, setIsNextDiceExplosive] = useRecoilState(isNextDiceExplosiveState);
	const isL5RDice = useRecoilValue(isL5RDiceState);
	const customDiceSelected = useRecoilValue(customDiceSelectedState);
	const [isNextDiceRepetition, setIsNextDiceRepetition] =
		useRecoilState(isNextDiceRepetitionState);
	const [repeatDices, setRepeatDices] = useRecoilState(repeatDicesState);
	let diceNotation = '1d100';
	let container;

	let camera, scene, renderer;
	let geometry, material, mesh;

	useEffect(() => {
		if (Array.isArray(roll)) {
			container = document.getElementById('container');
			diceNotation = '';
			roll.map((x) => {
				if (x === 100) {
					diceNotation += '1d100+1d10+';
				} else {
					diceNotation += `1d${x}+`;
				}
			});
			diceInit(container, setResult, diceNotation);
			rollDice();
		} else if (roll > 0) {
			container = document.getElementById('container');
			//var box = new dice().dice_box(container, { w: 500, h: 300 });
			//console.log('%%%%%%%%%%BBBBBBBBBBBBBBBBBBBBBBBBBOX',box);
			//box.animate_selector = false;
			//init();
			if (roll === 100) {
				diceNotation = '1d100+1d10';
			} else {
				diceNotation = `1d${roll}`;
			}
			diceInit(container, setResult, diceNotation);
			rollDice();
		}
	}, [roll]);

	useEffect(() => {
		if (result && (!customDiceSelected || !Array.isArray(roll))) {
			let localResult = result;
			if (roll === 100) {
				localResult = localResult.map((x, i) => (i === 1 ? x % 10 : x));
			}
			if (roll === 100) {
				setDiceResults([
					{
						notation: roll,
						value: localResult.reduce((a, b) => a + b, 0),
						type: DICE_TYPES.NORMAL,
						date: new Date().getTime(),
					},
					...diceResults,
				]);
			} else {
				let newResult;
				if (Array.isArray(roll)) {
					newResult = roll.map((notation, i) => {
						return {
							notation,
							value: localResult[i],
							type: isL5RDice ? DICE_TYPES.L5R : DICE_TYPES.NORMAL,
							date: new Date().getTime(),
						};
					});
				} else {
					newResult = [
						{
							notation: roll,
							value: localResult[0],
							type: DICE_TYPES.NORMAL,
							date: new Date().getTime(),
						},
					];
				}
				if (isNextDiceExplosive) {
					setIsNextDiceExplosive(false);
					setDiceResults([...newResult, ...diceResults]);
				} else if (isNextDiceRepetition) {
					setIsNextDiceRepetition(false);
					const notReroledDices = diceResults.filter(
						(x) =>
							!repeatDices.some((y) => y.id === x.id) && x.type !== DICE_TYPES.CUSTOM
					);
					setDiceResults([...notReroledDices, ...newResult]);
				} else {
					setDiceResults([...newResult]);
				}

				// setDiceResults([...newResult, ...diceResults]);
			}

			setTimeout(() => {
				setRoll(0);
			}, 1500);
		}
	}, [result]);

	function init() {
		camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 10);
		camera.position.z = 1;

		scene = new THREE.Scene();

		geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
		material = new THREE.MeshNormalMaterial();

		mesh = new THREE.Mesh(geometry, material);
		scene.add(mesh);

		renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setAnimationLoop(animation);
		renderer.setClearColor(0x000000, 0); // the default
		container.appendChild(renderer.domElement);
	}

	function animation(time) {
		mesh.rotation.x = time / 2000;
		mesh.rotation.y = time / 1000;

		renderer.render(scene, camera);
	}

	return (
		<>
			{roll ? (
				<div className="dice-area">
					<div id="container" />
					<div key="canvas" id="canvas" />
					<input
						key="input"
						type="text"
						id="set"
						value={diceNotation}
						readOnly="readonly"
					/>
					<div key="label" id="label" />
					<div key="selector" id="selector_div" />
					<div key="info" id="info_div" />
					<div key="throw" id="throw" />
				</div>
			) : null}
			<CustomDiceResults result={result} />
		</>
	);
};

export default memo(Dices);
