import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const focusedStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

function Dropzone({ onFileReady }) {
	const { t, i18n } = useTranslation();
	const onDrop = useCallback(
		(acceptedFiles) => {
			console.log('appcepted files', acceptedFiles);
			acceptedFiles.forEach((file) => {
				console.log('receivedfiles', file);
				const reader = new FileReader();

				reader.onabort = () => console.log('file reading was aborted');
				reader.onerror = () => console.log('file reading has failed');
				reader.onload = () => {
					// Do whatever you want with the file contents
					const binaryStr = reader.result;
					file.binary = binaryStr;
					onFileReady(file);
				};
				reader.readAsDataURL(file);
			});
		},
		[onFileReady]
	);
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
		accept: 'image/png, image/jpeg,audio/*',
		maxFiles: 2,
		onDrop,
	});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	return (
		<Box>
			<Box {...getRootProps({ style })}>
				<Typography>{t('dragnDrop.text')}</Typography>
				<Box mt={2}>
					<Button variant="contained" component="label">
						{t('dragnDrop.button')}
					</Button>
				</Box>
				<input
					className="input-zone"
					{...getInputProps()}
					hidden
					accept="image/png, image/jpeg,audio/*"
					multiple
					type="file"
				/>
			</Box>
		</Box>
	);
}

export default Dropzone;
