import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { DICE_TYPES } from './../../common/constants';
import { getDiceClasses } from './../../common/services/l5rDiceTranslator';

//import './DiceResult.scss';

const useStyles = makeStyles((theme) => ({
	l5r: {
		width: '28px',
		height: '27px',
		background: 'white',
		'background-size': 'contain',
		position: 'relative',
		top: -'4px',
		left: -'4px',
		'&.strife': {
			'background-image': "url('/images/dices/l5r/strife.png')",
		},
		'&.opportunity': {
			'background-image': "url('/images/dices/l5r/opportunity.png')",
		},
		'&.success': {
			'background-image': "url('/images/dices/l5r/success.png')",
		},
		'&.explosive-success': {
			'background-image': "url('/images/dices/l5r/explosive-success.png')",
		},
		'&.two_Values': {
			'background-position': 'right bottom, left top',
			'background-size': '14px, 14px',
			'background-repeat': 'no-repeat',
		},
		'&.two_Values.opportunity.success': {
			'background-image':
				"url('/images/dices/l5r/success.png'), url('/images/dices/l5r/opportunity.png')",
		},
		'&.two_Values.strife.opportunity': {
			'background-image':
				"url('/images/dices/l5r/strife.png'), url('/images/dices/l5r/opportunity.png')",
		},
		'&.two_Values.strife.success': {
			'background-image':
				"url('/images/dices/l5r/strife.png'), url('/images/dices/l5r/success.png')",
		},
		'&.two_Values.strife.explosive-success': {
			'background-image':
				"url('/images/dices/l5r/strife.png'), url('/images/dices/l5r/explosive-success.png')",
		},
	},
}));

const DiceResult = ({ notation, result, type }) => {
	const classes = useStyles();
	const renderL5RValues = () => {
		return (
			<Box
				className={`l5r-dice ${getDiceClasses(notation, result)} ${classes.l5r}`}
				src="/images/dices/l5r/success.png"></Box>
		);
	};

	return DICE_TYPES.NORMAL === type ? result : renderL5RValues();
};

export default DiceResult;
