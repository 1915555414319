import { EMPTY_FOLDER, LIBRARY_TYPE, LIBRARY_ITEMS_TYPES } from '../constants';
import { createLibrary } from './libraryRequests';

export const findAndAddElement = (
	currentLibrary,
	currentBreadcrumb,
	name,
	showError,
	type = LIBRARY_ITEMS_TYPES.FOLDER,
	file
) => {
	if (currentBreadcrumb.length === 0) {
		if (type === LIBRARY_ITEMS_TYPES.FOLDER) {
			currentLibrary = addFolderToLibrary(currentLibrary, name, showError);
		} else if (type === LIBRARY_ITEMS_TYPES.FILE) {
			currentLibrary = addFileToLibrary(currentLibrary, name, file, showError);
		}
	} else {
		let childFolder = getChildrenFolder(currentLibrary, currentBreadcrumb[0]);
		childFolder = findAndAddElement(
			childFolder,
			currentBreadcrumb.slice(1),
			name,
			showError,
			type,
			file
		);
		currentLibrary = updateChildren(currentLibrary, currentBreadcrumb[0], childFolder);
	}
	return currentLibrary;
};

export const findAndDeleteElement = (
	currentLibrary,
	currentBreadcrumb,
	name,
	showError,
	type = LIBRARY_ITEMS_TYPES.FOLDER,
	file
) => {
	if (currentBreadcrumb.length === 0) {
		currentLibrary = deletElementFromLibrary(currentLibrary, name, showError);
	} else {
		let childFolder = getChildrenFolder(currentLibrary, currentBreadcrumb[0]);
		childFolder = findAndDeleteElement(
			childFolder,
			currentBreadcrumb.slice(1),
			name,
			showError,
			type,
			file
		);
		currentLibrary = updateChildren(currentLibrary, currentBreadcrumb[0], childFolder);
	}
	return currentLibrary;
};

const getChildrenFolder = (currentLibrary, nextStep) => {
	return currentLibrary.children.find((x) => x.name === nextStep);
};

const updateChildren = (currentLibrary, name, child) => {
	const newChild = currentLibrary.children.map((x) => {
		if (x.name === name) {
			return child;
		} else {
			return x;
		}
	});

	const newCurrentLibary = { ...currentLibrary };

	newCurrentLibary.children = newChild;
	return newCurrentLibary;
};

const addFolderToLibrary = (currentLibrary, name, showError) => {
	if (!checkElementExists(currentLibrary, name)) {
		const newFolder = { ...EMPTY_FOLDER };
		newFolder.name = name;
		currentLibrary = { ...currentLibrary };
		currentLibrary.children = [...currentLibrary.children];
		currentLibrary.children.push(newFolder);
	} else {
		try {
			showError();
		} catch (e) {
			console.log(e);
		}
	}
	return currentLibrary;
};

const checkElementExists = (container, name) => {
	return container?.children.some((x) => x.name === name);
};

const addFileToLibrary = (currentLibrary, name, file, showError) => {
	if (!checkElementExists(currentLibrary, name)) {
		currentLibrary = { ...currentLibrary };
		currentLibrary.children = [...currentLibrary.children];
		const newFile = { ...file };
		newFile.name = name;
		newFile.type = LIBRARY_ITEMS_TYPES.FILE;
		currentLibrary.children.push(newFile);
	} else {
		try {
			showError();
		} catch (e) {
			console.log(e);
		}
	}
	return currentLibrary;
};

const deletElementFromLibrary = (currentLibrary, name, showError) => {
	if (checkElementExists(currentLibrary, name)) {
		currentLibrary = { ...currentLibrary };
		currentLibrary.children = [...currentLibrary.children.filter((x) => x.name !== name)];
	} else {
		try {
			showError();
		} catch (e) {
			console.log(e);
		}
	}
	return currentLibrary;
};

const removeLinks = (libraryChild) => {};

export const saveLibrary = async (
	currentLibraryType,
	privateLibraries,
	currentLibraryContent,
	publicLibraries,
	rulesLibrary,
	currentUserInfo,
	setPrivateLibraries,
	setPublicLibraries,
	setRulesLibrary
) => {
	const isPrivate = currentLibraryType === LIBRARY_TYPE.PRIVATE;
	const isPublic = currentLibraryType === LIBRARY_TYPE.PUBLIC;
	const isRule = currentLibraryType === LIBRARY_TYPE.RULES;
	let currentLibraryEntry;
	if (isPrivate) {
		currentLibraryEntry = privateLibraries.find((x) => x.name === currentLibraryContent.name);
	} else if (isPublic) {
		currentLibraryEntry = publicLibraries.find((x) => x.name === currentLibraryContent.name);
	} else {
		currentLibraryEntry = rulesLibrary.find((x) => x.name === currentLibraryContent.name);
	}
	if (!currentLibraryEntry) {
		currentLibraryEntry = {
			name: currentLibraryContent.name,
			userId: isPrivate ? currentUserInfo.username : isPublic ? 'public' : 'rules',
		};
	}
	const alreadyInDb = !!currentLibraryEntry.key;
	// const libraryWithoutUrls = removeLinks(currentLibraryContent);
	// console.log('libraryWithoutUrls', libraryWithoutUrls);
	// console.log('currentLibraryContent', currentLibraryContent);
	const response = await createLibrary(
		currentLibraryEntry.key,
		isPrivate,
		isRule,
		currentLibraryContent,
		currentLibraryEntry.name
	);
	if (!alreadyInDb) {
		currentLibraryEntry = { ...currentLibraryEntry };
		currentLibraryEntry.key = response.ID;
		if (isPrivate) {
			const newLibraryEntry = [
				...privateLibraries.filter((x) => x.name !== currentLibraryContent.name),
				currentLibraryEntry,
			];
			setPrivateLibraries(newLibraryEntry);
		} else if (isPublic) {
			const newLibraryEntry = [
				...publicLibraries.filter((x) => x.name !== currentLibraryContent.name),
				currentLibraryEntry,
			];
			setPublicLibraries(newLibraryEntry);
		} else {
			const newLibraryEntry = [
				...rulesLibrary.filter((x) => x.name !== currentLibraryContent.name),
				currentLibraryEntry,
			];
			setRulesLibrary(newLibraryEntry);
		}
	}
	return currentLibraryEntry;
};
