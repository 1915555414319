import * as React from 'react';
import {
	Button,
	CardActionArea,
	CardActions,
	Card,
	CardContent,
	CardMedia,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function GameCard({ game }) {
	const { t, i18n } = useTranslation();

	const redirectToGame = () => {
		window.location.href = game.link;
	};

	return (
		<Card sx={{ maxWidth: 345 }}>
			<CardActionArea onClick={redirectToGame}>
				<CardMedia component="img" height="140" image={game.img} alt={game.name} />
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{game.name}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{game.description}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button size="small" color="primary" onClick={redirectToGame}>
					{t('management.game.card.play')}
				</Button>
			</CardActions>
		</Card>
	);
}
