export const CAMERA_TYPE = {
	DEFAULT: 1,
	HIGHLIGHTED_USERS: 2,
};
export const CHAT_EVENTS = {
	DICE_RESULT: 1,
	SHARING: 2,
	CLEAR_DICE_RESULTS: 3,
	USER_NAME: 4,
	CAMERA_LAYOUT: 5,
	SHARE_S3_IMAGE: 6,
	SHARE_S3_AUDIO: 7,
	AVATAR: 8,
	VIDEO_STATUS: 9,
	MIC_STATUS: 10,
	CHAT: 11,
	CHAT_WARNING: 12,
	COUNTDOWN: {
		START: 13,
		END: 14,
	},
	KILL: -9,
};
export const CLIENT_EVENTS = {
	ENTER_ROOM: 1,
	SIGNALING: 2,
	STREAM_READY: 3,
	CHAT_READY: 4,
	INCOMING_CHAT: 5,
	ROOM_UPDATE: 6,
	RESYNC_USER: 7,
	DELETE_USER: 8,
};
export const DICE_TYPES = {
	NORMAL: 1,
	L5R: 2,
	RESULT: 3,
	RESULT_SUCCESS: 4,
	RESULT_FAIL: 5,
	CUSTOM: 6,
};
export const FAKE_STREAM = 'fake_stream';
export const COUNTDOWN_CAMERA = 'count_down_camera';
export const IS_LOCALHOST = window.location.hostname === 'localhost';
export const SIZES = {
	small: 24,
	medium: 40,
	big: 100,
};
export const USER_STREAM_STATUS = {
	CREATED: 0,
	SYNC: 1,
	ESTABLISHED: 2,
};

export const IMAGE_MIMES = ['image/jpeg', 'image/png', 'image/jpg'];
export const AUDIO_MIMES = ['audio/mpeg'];

export const LIBRARY_TYPE = {
	PUBLIC: 0,
	PRIVATE: 1,
	RULES: 2,
};

export const LIBRARY_ITEMS_TYPES = {
	FOLDER: 1,
	FILE: 2,
	CREATE_FOLDER: 3,
};

export const EMPTY_LIBRARY = {
	name: '',
	dirty: true,
	type: LIBRARY_ITEMS_TYPES.FOLDER,
	children: [],
};

export const EMPTY_FOLDER = {
	name: '',
	dirty: true,
	children: [],
	type: LIBRARY_ITEMS_TYPES.FOLDER,
};

export const SLEEP = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
