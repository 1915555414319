import React, { useCallback, useState } from 'react';
import { grey } from '@mui/material/colors/';
import {
	AppBar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Divider,
	Drawer,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Toolbar,
	Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, InsertDriveFile, StopScreenShare } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import { Auth } from 'aws-amplify';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
	shareCanvasImgState,
	sharedAudiosState,
	sharedImageState,
	showFileManagerState,
	uploadedImagesState,
} from './../../common/recoil/Share/atom';
import { addNewFile } from './../../common/recoil/Share/selectors';
import { CHAT_EVENTS, IMAGE_MIMES, AUDIO_MIMES } from './../../common/constants';
import { DropZone } from './../../common/components';
import { useChat } from './../../common/hooks';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	folderTree: {
		backgroundColor: grey[200],
		width: 300,
		height: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		overflowY: 'scroll',
	},
	dropZone: {
		border: `dashed ${grey[500]}`,
		padding: '5px',
		margin: '5px',
	},
	imageList: {
		width: 500,
		height: 450,
	},
	fileIcon: {
		width: '50%',
		height: '50%',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	stopShareImage: {
		position: 'absolute',
	},
}));

const FileManager = () => {
	const classes = useStyles();
	const [showFileManager, setShowFileManager] = useRecoilState(showFileManagerState);
	const [uploadedImages, setUploadedImages] = useRecoilState(uploadedImagesState);
	const [sharedImage, setSharedImage] = useRecoilState(sharedImageState);
	const [sharedAudios, setSharedAudios] = useRecoilState(sharedAudiosState);
	const [shareCanvasImg, setShareCanvasImg] = useRecoilState(shareCanvasImgState);
	const [filesUploaded, setNewFile] = useRecoilState(addNewFile);
	const [uploadingFile, setUploadingFile] = useState(false);
	const { messages, sendMessage } = useChat();
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const uploadImage = async (image) => {
		setUploadingFile(true);
		const currentSession = await Auth.currentSession();

		let parts = image.split(';');
		let mime = parts[0].split(':').slice(1, 2)[0];
		let name = parts[1].split('=').slice(1, 100).join('');
		let data = parts[1];

		const imageToSend = {
			mime: mime,
			name: 'image_2021-07-01_18-58-52.png',
			image: data,
		};
		const config = {
			headers: {
				Accept: '*/*',
				Authorization: currentSession.getIdToken().getJwtToken(),
				'content-type': '',
				'X-API-KEY': 'ee6J8LUPwn9dJhxAqwkrU3R7Xi2UuIb18QjoNs0p',
			},
		};
		const response = await Axios.post(
			'https://h4ckpdm4hg.execute-api.eu-west-1.amazonaws.com/dev/imageUpload',
			imageToSend,
			config
		);
		setUploadingFile(false);
		if (response.data.statusCode === 200) {
			addImage({
				asset: response.data.body.imageURL,
				isImage: IMAGE_MIMES.includes(response.data.body.mime),
				isAudio: AUDIO_MIMES.includes(response.data.body.mime),
				mime: response.data.body.mime,
			});
		} else {
			showError(response.data.body.message);
		}
	};

	const getSignedUrl = async (file) => {
		setUploadingFile(true);
		const currentSession = await Auth.currentSession();
		const currentCredentials = await Auth.currentCredentials();
		const currentUserInfo = await Auth.currentUserInfo();

		const userName = currentUserInfo.username;
		const extension = file.name.split('.')[1];
		try {
		} catch (e) {
			console.log('!!!!!!!!!!!!!!!!!!!error', e);
		}

		const config = {
			headers: {
				Accept: '*/*',
				Authorization: currentSession.getIdToken().getJwtToken(),
				'content-type': '',
				'X-API-KEY': 'ee6J8LUPwn9dJhxAqwkrU3R7Xi2UuIb18QjoNs0p',
			},
		};
		debugger;
		const response = await Axios.get(
			`https://h4ckpdm4hg.execute-api.eu-west-1.amazonaws.com/dev/getS3PresignedPostUrl?userId=${userName}&extension=${extension}`,
			config
		);

		const signedInfo = response.data.body;

		let form = new FormData();
		Object.keys(signedInfo.fields).forEach((key) => form.append(key, signedInfo.fields[key]));
		form.append('file', file);
		const fileResponse = await axios.post(signedInfo.url, form);

		const signedFile = await Axios.get(
			`https://h4ckpdm4hg.execute-api.eu-west-1.amazonaws.com/dev/getSignedFile?key=${signedInfo.fields['key']}`,
			config
		);

		setUploadingFile(false);
		if (signedFile.data.statusCode === 200) {
			addImage({
				asset: signedFile.data.body.imageURL,
				isImage: IMAGE_MIMES.includes(file.type),
				isAudio: AUDIO_MIMES.includes(file.type),
				mime: file.type,
				key: signedInfo.fields['key'],
				id: signedInfo.fields['key'].split('/')[1].split('.')[0].replaceAll('-', ''),
			});
		} else {
			showError(signedFile.data.body.message);
		}
	};

	const showError = (message) => {
		enqueueSnackbar(message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			action: (key) => (
				<Button onClick={onClickDismiss(key)}>
					<Close color={'white'}></Close>
				</Button>
			),
			preventDuplicate: true,
		});
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const addImage = (file) => {
		const tempUploadedImages = [...uploadedImages];
		//setUploadedImages([file, ...tempUploadedImages]);
		setNewFile(file);
	};

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();

			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');
			reader.onload = () => {
				// Do whatever you want with the file contents
				const binaryStr = reader.result;
				console.log(binaryStr);
				//uploadImage(reader.result);
			};
			reader.readAsDataURL(file);
			try {
				getSignedUrl(file);
			} catch (e) {
				console.log('error', e);
				setUploadingFile(false);
			}
		});
	}, []);

	const onImgClick = (img) => {
		if (img.isImage) {
			if (img.key === sharedImage.key) {
				stopShareImage();
			} else {
				setSharedImage(img);
				setShareCanvasImg(img.asset);
				const shareImgMessage = {
					type: CHAT_EVENTS.SHARE_S3_IMAGE,
					img: img.asset,
				};
				sendMessage(shareImgMessage);
			}
		} else if (img.isAudio) {
			const tempAudios = [...sharedAudios];
			if (!sharedAudios.some((x) => x.key === img.key)) {
				tempAudios.push(img);
				setSharedAudios(tempAudios);
				const shareImgMessage = {
					type: CHAT_EVENTS.SHARE_S3_AUDIO,
					sharedAudio: tempAudios,
				};
				sendMessage(shareImgMessage);
			}
		}
	};

	const stopShareImage = () => {
		setShareCanvasImg(null);
		setSharedImage(null);
		const shareImgMessage = {
			type: CHAT_EVENTS.SHARE_S3_IMAGE,
			img: null,
		};
		sendMessage(shareImgMessage);
	};

	const foldersTree = () => {
		return (
			<Box className={classes.folderTree}>
				<ImageList
					rowHeight={160}
					// className={classes.imageList}
					cols={2}>
					{uploadedImages.map((file) => (
						<ImageListItem
							onClick={() => {
								onImgClick(file);
							}}
							key={file.asset}
							cols={1}>
							{file.isImage ? (
								<img src={file.asset} alt={file.asset} />
							) : (
								<InsertDriveFile className={classes.fileIcon}></InsertDriveFile>
							)}
							{file.isImage && file.key === sharedImage?.key && (
								<ImageListItemBar
									title="Stop Sharing"
									subtitle={
										<StopScreenShare
											className={classes.stopShareImage}
											onClick={stopShareImage}></StopScreenShare>
									}
									position="below"
								/>
							)}
						</ImageListItem>
					))}
				</ImageList>
				<Dropzone onDrop={onDrop}>
					{({ getRootProps, getInputProps }) => (
						<section className={classes.dropZone}>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<p>Drag 'n' drop some files here, or click to select files</p>
							</div>
						</section>
					)}
				</Dropzone>
			</Box>
		);
	};

	return (
		<Drawer anchor="right" open={showFileManager} onClose={() => setShowFileManager(false)}>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6">{t('fileManager.title')}</Typography>
				</Toolbar>
			</AppBar>
			{foldersTree()}
			<Backdrop className={classes.backdrop} open={uploadingFile}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Drawer>
	);
};

export default FileManager;
