import React, { useEffect, useMemo, useRef, useState } from 'react';
import PubSub from 'pubsub-js';
import { Box, IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PlayCircle, Close } from '@mui/icons-material/';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { CHAT_EVENTS, COUNTDOWN_CAMERA } from '../../common/constants';
import {
	shareCountDownState,
	shareCountDownCurrentTimeState,
	shareCountDownTimeState,
} from './../../common/recoil/Share/atom';
import {
	remoteMediaStreamsSelector,
	removeMediaStreamsSelector,
} from './../../common/recoil/websocket/selectors';
import { userIdState } from './../../common/recoil/UserInfo/atoms';
import { useChat } from './../../common/hooks';

const useStyles = makeStyles(() => ({
	container: {
		pointerEvents: 'none',
		height: '100%',
		width: '100%',
		width: '66vmin',
		height: '66vmin',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: '"Oswald", sans-serif',
		fontWeight: 400,
		fontSize: '25vmin',
		overflow: 'hidden',
		cursor: 'pointer',
		transition: 'width, height, border-radius, font-size',
		transitionDuration: '0.2s',
		background: '#FF5722',
	},
	fill: {
		display: 'block',
		width: '100%',
		height: '100%',
		position: 'absolute',
		left: 0,
		bottom: 0,
		background: 'black',
		opacity: 1,
	},
	digit: {
		width: '100%',
		color: '#FF5722',
		textAlign: 'center',
		mixBlendMode: 'difference',
		PointerEvents: 'none',
		'-webkit-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		userSelect: 'none',
	},
	control: {
		backgroundColor: 'hsla(120, 100%, 100%, 0.3)',
		padding: '50px',
	},
	textArea: {
		background: 'white',
		width: '25%',
	},
	largeIcon: {
		width: 60,
		height: 60,
		color: 'black',
	},
	closeIcon: {
		width: 60,
		height: 60,
		color: 'white',
	},
}));

const CountDown = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const id = useRecoilValue(userIdState);
	const { messages, sendMessage } = useChat(id);

	const [shareCountDown, setShareCountDown] = useRecoilState(shareCountDownState);
	const [shareCountDownCurrentTime, setCountDownCurrentTime] = useRecoilState(
		shareCountDownCurrentTimeState
	);
	const [shareCountDownTime, setShareCountDownTime] = useRecoilState(shareCountDownTimeState);
	const [countDownTempTime, setCountDownTempTime] = useState(0);
	const [removeMediaStreams, setRemoveMediaStreams] = useRecoilState(removeMediaStreamsSelector);

	let hoursRef = useRef('');
	let minutesRef = useRef('');
	let secondsRef = useRef('');

	useEffect(() => {
		if (shareCountDownTime !== 0) {
			setCountDownCurrentTime(shareCountDownTime);
			if (shareCountDown) {
				const startCoundown = {
					type: CHAT_EVENTS.COUNTDOWN.START,
					value: shareCountDownTime,
					id: id,
				};
				sendMessage(startCoundown);
			}
		} else {
			setCountDownCurrentTime(0);
		}
	}, [shareCountDownTime]);

	useEffect(() => {
		if (shareCountDownCurrentTime !== 0) {
			setTimeout(() => {
				if (shareCountDownTime !== 0) {
					let tempContdownTime = shareCountDownCurrentTime;
					setCountDownCurrentTime(tempContdownTime - 1);
				} else {
					if (!shareCountDown) {
						setCountDownCurrentTime(0);
						setRemoveMediaStreams(COUNTDOWN_CAMERA);
					}
				}
			}, 1000);
		}
	}, [shareCountDownCurrentTime]);

	const countdownHeight = useMemo(() => {
		const percentage = (shareCountDownCurrentTime / shareCountDownTime) * 100;
		return `${percentage}%`;
	}, [shareCountDownCurrentTime]);

	return (
		<>
			{shareCountDown && (
				<Box position="absolute" right="0" top="0" zIndex="10000">
					<IconButton
						onClick={() => {
							setRemoveMediaStreams(COUNTDOWN_CAMERA);
							const startCoundown = {
								type: CHAT_EVENTS.COUNTDOWN.END,
								id: id,
							};
							sendMessage(startCoundown);
							setShareCountDown(false);
							setShareCountDownTime(0);
						}}
						aria-label="close">
						<Close color="secondary" className={classes.closeIcon} fontSize="inherit" />
					</IconButton>
				</Box>
			)}
			{(shareCountDown || shareCountDownTime > 0) && (
				<Box className={classes.container}>
					<Box className={classes.fill} style={{ height: countdownHeight }}></Box>
					<Box className={classes.digit}>{shareCountDownCurrentTime}</Box>
				</Box>
			)}
			{shareCountDown && shareCountDownTime === 0 && (
				<Box className={classes.control}>
					<Box display="flex" justifyContent="space-evenly">
						<TextField
							className={classes.textArea}
							defaultValue={0}
							id="hours"
							label={t('countDown.hours')}
							inputRef={hoursRef}
							type="number"
							variant="outlined"
						/>
						<TextField
							className={classes.textArea}
							defaultValue={0}
							id="minutes"
							label={t('countDown.minutes')}
							inputRef={minutesRef}
							type="number"
							variant="outlined"
						/>
						<TextField
							className={classes.textArea}
							defaultValue={0}
							id="seconds"
							label={t('countDown.seconds')}
							inputRef={secondsRef}
							type="number"
							variant="outlined"
						/>
					</Box>
					<Box display="flex" justifyContent="space-evenly">
						<IconButton
							onClick={() => {
								setShareCountDownTime(
									parseInt(hoursRef.current.value * 3600) +
										parseInt(minutesRef.current.value * 60) +
										parseInt(secondsRef.current.value)
								);
							}}
							aria-label="start">
							<PlayCircle className={classes.largeIcon} fontSize="inherit" />
						</IconButton>
						{countDownTempTime}
					</Box>
				</Box>
			)}
		</>
	);
};

export default CountDown;
