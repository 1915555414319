import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material/';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { DICE_TYPES } from './../../common/constants';
import { getDicesResult } from './../../common/services/l5rDiceTranslator';
import { diceRollState } from './../../common/recoil/UserInfo/atoms';
import { isNextDiceExplosiveState } from './../../common/recoil/GameSystem/gameSystemAtom';

const DiceResultAlert = ({ isLocalUser, results, userName }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [shownResults, setShownResults] = useState([]);
	const [roll, setRoll] = useRecoilState(diceRollState);
	const [isNextDiceExplosive, setIsNextDiceExplosive] = useRecoilState(isNextDiceExplosiveState);

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const onExplodeDices = (key, explosiveNotations) => {
		closeSnackbar(key);
		setIsNextDiceExplosive(true);
		setRoll(explosiveNotations);
	};

	useEffect(() => {
		if (results?.length > 0 && Array.isArray(results)) {
			let dicesResult = getDicesResult(results, shownResults);
			setShownResults(results);
			if (dicesResult.someResult) {
				enqueueSnackbar(`${userName} ha sacado ${dicesResult.text}`, {
					variant: 'info',
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					action: (key) => (
						<Button onClick={onClickDismiss(key)}>
							<Close color={'white'}></Close>
						</Button>
					),
					preventDuplicate: true,
				});
				if (isLocalUser && dicesResult.explosiveNotations.length > 0) {
					setTimeout(() => {
						enqueueSnackbar(
							`Hay ${dicesResult.explosiveNotations.length} dados relanzables. ¿Quieres lanzarlos?`,
							{
								variant: 'success',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'center',
								},
								action: (key) => (
									<Button
										onClick={() => {
											onExplodeDices(key, dicesResult.explosiveNotations);
										}}>
										Lanzar
									</Button>
								),
							}
						);
					}, 1500);
				}
			}
		}
	}, [results]);

	return <Box></Box>;
};

export default DiceResultAlert;
