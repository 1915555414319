import React, { useState, useRef } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormGroup,
	FormControlLabel,
	TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';

import { EMPTY_FOLDER } from '../../../common/constants';
import { findAndAddElement } from '../../../common/services/libraryUtils';
import {
	currentLibraryBreadCrumbState,
	showCreateFolderDialogState,
} from '../../../common/recoil/Library/libraryAtom';
import { currentLibraryContentSelector } from '../../../common/recoil/Library/librarySelector';

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '200px',
	},
}));

const CreateFolderDialog = () => {
	const classes = useStyles();
	const [showCreateFolderDialog, setShowCreateFolderDialog] = useRecoilState(
		showCreateFolderDialogState
	);
	const [currentLibraryContent, setCurrentLibraryContent] = useRecoilState(
		currentLibraryContentSelector
	);
	const currentLibraryBreadCrumb = useRecoilValue(currentLibraryBreadCrumbState);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { t, i18n } = useTranslation();
	let nameInput = useRef();

	const handleSave = async () => {
		if (nameInput.current.value !== '') {
			let currentLibrary = { ...currentLibraryContent, dirty: true };
			currentLibrary.children = [...currentLibrary.children];
			currentLibrary = findAndAddElement(
				currentLibrary,
				currentLibraryBreadCrumb.slice(1),
				nameInput.current.value,
				showError
			);
			setCurrentLibraryContent(currentLibrary);
			setShowCreateFolderDialog(false);
		}
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const showError = () => {
		enqueueSnackbar(t('LibrariesManager.createFolderDialog.existsFolder'), {
			variant: 'error',
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'center',
			},
			action: (key) => (
				<Button onClick={onClickDismiss(key)}>
					<Close color={'white'}></Close>
				</Button>
			),
			preventDuplicate: true,
		});
	};

	return (
		<Dialog open={showCreateFolderDialog} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">
				{t('LibrariesManager.createFolderDialog.title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('LibrariesManager.createFolderDialog.content')}
				</DialogContentText>
				<FormGroup row>
					<TextField
						data-testid="userNameField"
						autoFocus
						margin="dense"
						id="name"
						label={t('LibrariesManager.createFolderDialog.placeholder')}
						type="text"
						fullWidth
						inputRef={nameInput}
						onKeyPress={(event) => {
							if (event.key === 'Enter') {
								handleSave();
							}
						}}
					/>
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setShowCreateFolderDialog(false);
					}}
					color="primary"
					data-testid="cancel">
					{t('diceSelector.buttons.cancel')}
				</Button>
				<Button onClick={handleSave} color="primary" data-testid="saveUserInfoButton">
					{t('userInfo.buttons.save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateFolderDialog;
